import { getCreateAppointmentUrl, getUpdateAppointmentUrl } from "../api/apiEndpoints";
import { apiRequest } from "../api/apiRequest";

const useCreateAppointment = accessToken => {
  const createAppointment = async (customerId, data) => {
    return apiRequest({
      url: getCreateAppointmentUrl(customerId),
      method: "post",
      data,
      configs: {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    });
  };
  const updateAppointment = async (customerId, data) => {
    return apiRequest({
      url: getUpdateAppointmentUrl(customerId),
      method: "post",
      data,
      configs: {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    });
  };

  return { createAppointment, updateAppointment };
};

export default useCreateAppointment;
