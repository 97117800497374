import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { InView } from "react-intersection-observer";
import { useRecoilState } from "recoil";

import { customerAtom } from "./atoms/customer";
import { stepAtom } from "./atoms/step";
import StepsSidebar from "./steps/New/StepsSidebar";
import StepsMenu from "./steps/New/StepsMenu";
import Title from "./components/Title";
import SubTitle from "./components/SubTitle";

import ScheduleAppointment from "./steps/New/ScheduleAppointment";
import useTracking from "./hook/useTrackingNew";
import useUpdateCustomerData from "./hook/useUpdateCustomerData";

import "./styles.scss";
import { useFeatureFlag } from "configcat-react";
import ServiceCheck from "./steps/New/ServiceCheck";
import CustomerSummary from "./steps/New/CustomerSummary/CustomerSummary";
import SelectPlan from "./steps/New/SelectPlan";
import PaymentInfoIframe from "./steps/New/PaymentInfoIframe";
import OrderSummary from "./steps/New/OrderSummary";
import OrderConfirmation from "./steps/New/OrderConfirmation";

export const steps = [
  {
    component: ServiceCheck,
    name: "Serviceability Check",
    title: (
      <Title className="main-title-lg">
        Get a tailored pest plan with <span className="unlimited">unlimited</span> support.
      </Title>
    ),
    subtitle: (
      <SubTitle className="px-0 mb-4 fst-italic subtitle-lg">
        To get started, please fill out the form below, including the address of the property to be treated.
      </SubTitle>
    ),
    mobileTitle: (
      <Title className="main-title-sm">
        Get a tailored pest plan with <span className="unlimited">unlimited</span> support.
      </Title>
    ),
    mobileSubtitle: (
      <SubTitle className="p-0 fst-italic subtitle-sm">
        To get started, please fill out the form below, including the address of the property to be treated.
      </SubTitle>
    ),
  },
  {
    component: SelectPlan,
    name: "Select a Plan",
    title: <Title className="main-title-lg">Great! We can service your home.</Title>,
    subtitle: <SubTitle className="px-0 mb-4 fst-italic subtitle-lg">Choose your Aptive plan.</SubTitle>,
    mobileTitle: <Title className="main-title-sm">Great! We can service your home!</Title>,
    mobileSubtitle: <SubTitle className="p-0 fst-italic subtitle-sm">Choose your Aptive plan.</SubTitle>,
  },
  {
    component: PaymentInfoIframe,
    name: "Payment Information",
    title: <Title className="main-title-lg">Payment Information</Title>,
    subtitle: (
      <SubTitle className="px-0 mb-4 fst-italic subtitle-lg">
        You will not be charged today. Payment will be due following each treatment.
      </SubTitle>
    ),
    mobileTitle: <Title className="main-title-sm">Payment Information</Title>,
    mobileSubtitle: (
      <SubTitle className="p-0 fst-italic subtitle-sm">
        You will not be charged today. Payment will be due following each treatment.
      </SubTitle>
    ),
  },
  {
    component: OrderSummary,
    name: "Order Summary",
    canGoBack: false,
    title: <Title className="main-title-lg">Order Summary</Title>,
    subtitle: (
      <SubTitle className="px-0 mb-4 fst-italic subtitle-lg">
        All of our plans include unlimited customer support and spot treatments
      </SubTitle>
    ),
    mobileTitle: <Title className="main-title-sm">Order Summary</Title>,
    mobileSubtitle: (
      <SubTitle className="p-0 fst-italic subtitle-sm">All of our plans include unlimited customer support and spot treatments</SubTitle>
    ),
  },
  {
    component: ScheduleAppointment,
    name: "Appointment Confirmation",
    canGoBack: false,
    title: <Title className="main-title-lg">Schedule Initial Appointment</Title>,
    subtitle: (
      <SubTitle className="px-0 mb-4 fst-italic subtitle-lg">
        Which time works best for you? You will need to be home for your initial appointment.
      </SubTitle>
    ),
    mobileTitle: <Title className="main-title-sm">Schedule Initial Appointment</Title>,
    mobileSubtitle: (
      <SubTitle className="p-0 fst-italic subtitle-sm">
        Which time works best for you? You will need to be home for your initial appointment.
      </SubTitle>
    ),
  },
  {
    component: OrderConfirmation,
    name: "Order Confirmation",
    canGoBack: false,
    title: <Title className="main-title-lg">Thank you for your order</Title>,
    subtitle: <SubTitle className="px-0 mb-4 fst-italic subtitle-lg">Your appointment has been successfully scheduled!</SubTitle>,
    mobileTitle: <Title className="main-title-sm">Thank you for your order</Title>,
    mobileSubtitle: <SubTitle className="p-0 fst-italic subtitle-sm">Your appointment has been successfully scheduled!</SubTitle>,
    hidden: true,
  },
];

const maxSteps = steps.length;

function sendContentHeight() {
  var height = document.body.scrollHeight;
  window.parent.postMessage({ height: height }, "*");
}

export default function NewCheckout() {
  const [step, setStep] = useRecoilState(stepAtom);
  const [sessionCreated, setSessionCreated] = useState(false);
  const { trackClick } = useTracking();
  const [customer, setCustomer] = useRecoilState(customerAtom);
  const { createSession, createSessionV2, updateCustomerData, updateCustomerDataV2, isLoading } = useUpdateCustomerData();
  const [spots, setSpots] = useState([]);

  const { value: newSessionApi, loading: newSessionApiLoading } = useFeatureFlag("newSessionApi", false);

  window.addEventListener("resize", sendContentHeight);

  // TODO - remove this once older versions of SCO go away
  if (!isMobile) {
    document.body.style.backgroundColor = "#3e4d3c";
  }

  const handleinViewChange = (inView, entry) => {
    if (inView && !sessionCreated) {
      const queryParams = new URLSearchParams(window.location.search);
      const queryStrings = {
        partner: queryParams.get("partner"),
        source: queryParams.get("source"),
        medium: queryParams.get("medium"),
        channel: queryParams.get("channel"),
        campaign: queryParams.get("campaign"),
        discountCode: queryParams.get("discountCode"),
        location: queryParams.get("location"),
        referrer: queryParams.get("referrer"),
        term: queryParams.get("term"),
        content: queryParams.get("content"),
        version: window.location.pathname,
      };
      const lms = {};

      for (const key in queryStrings) {
        if (Object.prototype.hasOwnProperty.call(queryStrings, key)) {
          lms[key] = queryStrings[key];
        }
      }
      setCustomer({ ...customer, lms });
      if (!newSessionApiLoading && newSessionApi) {
        createSessionV2({
          step: 0,
          lms,
        });
      }
      createSession({
        step: 0,
        lms,
      });
      
      // GTM event
      if(process.env.NODE_ENV === "production") {
        window.dataLayer.push({
          event: 'get_started'
        });
      }

      setSessionCreated(true);
    }
  };

  useEffect(() => {
    sendContentHeight();
  }, [step]);

  useEffect(() => {
    async function updateCustomer() {
      await updateCustomerData({ ...customer, step });
      if (!newSessionApiLoading && newSessionApi) {
        await updateCustomerDataV2({ ...customer, step });
      }
    }
    updateCustomer();
  }, [step]);

  const handleStepChange = newStep => {
    if (newStep >= 0 && newStep <= maxSteps) {
      setStep(newStep);
      trackClick(step, newStep);
    }
  };

  const CurrentComponent = steps[step].component;

  const isFirstStep = step === 0;
  const isPlanStep = step === 1;
  const isPaymentStep = step === 2;
  const isOrderSummaryStep = step === 3;
  const isScheduleStep = step === 4;
  const isConfirmation = step === steps.length - 1;

  return (
    <>
      {!newSessionApiLoading && (
        <InView as="div" onChange={handleinViewChange}>
          <div className="App">
            {isMobile ? (
              <div className="mobile-main-container">
                {!isConfirmation && <StepsSidebar steps={steps} active={step} className="mt-2" handleStepChange={handleStepChange} />}
                <div className="title-container">
                  {steps[step].mobileTitle}
                  {steps[step].mobileSubtitle}
                </div>
                <div className="current-component-container">
                  <CurrentComponent isLoading={isLoading} spots={spots} setSpots={setSpots} />
                </div>
              </div>
            ) : (
              <div className={`main-container row ${isConfirmation ? "w-50" : "w-75"}`}>
                <div className="title-container">
                  {steps[step].title}
                  {steps[step].subtitle}
                </div>

                {!isFirstStep && !isConfirmation && (
                  <div className="step-menu-and-summary-container">
                    {!(isOrderSummaryStep || isScheduleStep) && <CustomerSummary step={step} />}
                    <StepsMenu steps={steps} active={step} handleStepChange={handleStepChange} />
                  </div>
                )}
                <div
                  className={`current-component-container ${!isFirstStep && !isConfirmation && "col-9"} ${
                    step > 2 && step !== 5 && "bg-desktop"
                  } ${isPlanStep && ""} ${isPaymentStep && "bg-white"} ${isConfirmation && "bg-white"}
                  p-0`}
                >
                  <CurrentComponent isLoading={isLoading} spots={spots} setSpots={setSpots} />
                </div>
              </div>
            )}
          </div>
        </InView>
      )}
    </>
  );
}
