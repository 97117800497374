import ExpressPayment from "./ExpressPayment";
import ExpressSelectPlan from "./ExpressSelectPlan";

import { customerAtom } from "../../atoms/customer";
import { stepAtom } from "../../atoms/step";

import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import { apiRequest } from "../../api/apiRequest";
import { getFetchPaymentIframeUrl } from "../../api/apiEndpoints";

const PlanPayment = ({ updateSchedule }) => {
  const [customer, setCustomer] = useRecoilState(customerAtom);
  const step = useRecoilValue(stepAtom);  
  const [isSelected, setIsSelected] = useState(null);
  const [iframeURL, setIframeURL] = useState("");    

  useEffect(() => {
    if(customer?.officeId){
      fetchIframe(customer?.officeId);
    }    
  }, [customer?.officeId]);

  const handleSelectPlan = planNumber => {
    setCustomer({ ...customer, planNumber, step: step + 1 });
    planNumber ? setIsSelected(true) : setIsSelected(false);    
  };

  const fetchIframe = async (officeId) => {
    try {
      const res = await apiRequest({
        url: getFetchPaymentIframeUrl(officeId),
      });
      setIframeURL(res?.data?.url);
    } catch {      
      setIframeURL("");
    }
  };

  return (
    <>
      <ExpressSelectPlan
        showMessage
        isExpressCheckout
        handleSelectPlan={handleSelectPlan}
        isSelected={isSelected}
        setIsSelected={setIsSelected}
      />
      
      {isSelected && (
        <ExpressPayment
          isExpressCheckout
          iframeURL={iframeURL}          
          updateSchedule={updateSchedule}
        />
      )}
    </>
  );
};

export default PlanPayment;
