import Button from "./Button";
import { formatDateNotYear } from "../helpers/formatDateNotYear";
import { getWeekdayName } from "../helpers/getWeekday";
import { isMobile } from "react-device-detect";

export default function DaySchedule({ date, amSpot, pmSpot, onSelectSpot, selectedSpot }) {
  return (
    <div className={`date-slot d-flex align-items-center ${!isMobile ? "flex-column mx-1" : "justify-content-between"}`}>
      <div className={`date-slot-title col-3 ${!isMobile && "text-center w-auto mb-2"}`}>
        {getWeekdayName(date) + " " + formatDateNotYear(date, "d")}
      </div>
      <Button
        testId="am-select-btn"
        className={`col-3 am-select-btn-${isMobile ? "sm" : "lg mb-3"} ${
          amSpot && selectedSpot?.spotID === amSpot?.spotID ? "selected-date-slot" : ""
        } ${!amSpot && "not-avail fst-italic"}`}
        icon={null}
        onClick={() => onSelectSpot(amSpot)}
        disabled={!amSpot}
      >
        <span>
          {amSpot ? (
            "AM"
          ) : (
            <>
              <span>Not</span> <br /> available
            </>
          )}
        </span>
      </Button>
      <Button
        testId="pm-select-btn"
        className={`col-3 pm-select-btn-${isMobile ? "sm" : "lg"} ${
          pmSpot && selectedSpot?.spotID === pmSpot?.spotID ? "selected-date-slot" : ""
        } ${!pmSpot && "not-avail fst-italic"}`}
        icon={null}
        onClick={() => onSelectSpot(pmSpot)}
        disabled={!pmSpot}
      >
        <span>
          {pmSpot ? (
            "PM"
          ) : (
            <>
              <span>Not</span> <br /> available
            </>
          )}
        </span>
      </Button>
    </div>
  );
}
