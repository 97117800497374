var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { ThemeContext } from '../../context/ThemeContext';
var Progress = forwardRef(function (_a, ref) {
    var className = _a.className, _b = _a.value, value = _b === void 0 ? 0 : _b, props = __rest(_a, ["className", "value"]);
    var progress = useContext(ThemeContext).theme.progress;
    var progressContent = progress.content;
    var cls = classNames(className, progressContent);
    var progressValue = progress.value;
    var progressLabel = progress.label;
    var label = useMemo(function () {
        if (typeof value === 'string') {
            return value;
        }
        if (typeof value === 'number') {
            return "".concat(value, "%");
        }
        return value ? value.join(' ') : '';
    }, [value]);
    return (_jsx("div", __assign({ className: 'relative' }, { children: _jsxs("div", __assign({}, props, { ref: ref, className: cls, "aria-label": 'progress' }, { children: [_jsx("div", __assign({ className: "progresslabel ".concat(progressLabel) }, { children: label })), _jsx("div", { className: "progressvalue ".concat(progressValue), "aria-label": 'progressbar', style: {
                        width: typeof value === 'number' ? "".concat(value, "%") : 0,
                        transition: 'width 0.5s ease-out',
                    } })] })) })));
});
Progress.displayName = 'Progress';
export default Progress;
