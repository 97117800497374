var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/**
 * Primary UI component for user interaction
 */
export var Brand = function (_a) {
    var id = _a.id, className = _a.className, backgroundColor = _a.backgroundColor, label = _a.label, imgSrc = _a.imgSrc, width = _a.width, height = _a.height, props = __rest(_a, ["id", "className", "backgroundColor", "label", "imgSrc", "width", "height"]);
    return (_jsx("img", __assign({ id: id, className: "prose ".concat(className), width: width, height: height, src: imgSrc, style: { background: backgroundColor }, alt: label }, props)));
};
