import React, { useState } from "react";
import { AptiveIcon } from '@aptive-env/storybook';

import StepsMenu from './StepsMenu';
import CustomerSummary from "./CustomerSummary/CustomerSummary";

const StepsSidebar = ({
  steps = [],
  active,  
  handleStepChange
}) => {
  const [open, setOpen] = useState(false);

  const openMenu = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const onHandleStepChange = (index) => {
      closeMenu();
      handleStepChange(index);
  };

  return (
    <>
      {active >= 1 &&
        <div className={'steps-button-container'}>
          <button
            data-testid="step-menu-button"
            type="button"
            onClick={openMenu}
            className={`icon-button step-menu-button`}
          >
            <i className="fa fa-solid fa-bars"/>
          </button>

          <div>
            <div className="step-number">Step {active + 1} of {steps.length - 1}</div>
            <div className="step-label">{steps[active].name}</div>
          </div>
        </div>
      }

      {open &&
        <>
          <div className={`steps-sidebar-mask`}/>
          
          <div className={`steps-sidebar-container`}>
            <button
              data-testid="close-button"
              type="button"
              onClick={closeMenu}
              className={`icon-button close-button`}
            >
              <AptiveIcon
                className="w-[24px] h-[24px] fill-[#9CA3AF] stroke-none"
                icon="x"
                isFilled
              />
            </button>
            
            <div className={"customer-summary-container"}>
              <CustomerSummary step={active}/>
            </div>
            <StepsMenu steps={steps} active={active} handleStepChange={onHandleStepChange}/>
          </div>
        </>
      }
    </>
  );
};

export default StepsSidebar;
