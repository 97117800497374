var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useContext } from 'react';
import classNames from 'classnames';
import AptiveIcon from '../Icon';
import { ThemeContext } from '../../context/ThemeContext';
var Tooltip = forwardRef(function (_a, ref) {
    var className = _a.className, message = _a.message, children = _a.children, icon = _a.icon, _b = _a.position, position = _b === void 0 ? 'top' : _b, _c = _a.alignment, alignment = _c === void 0 ? 'center' : _c, open = _a.open, props = __rest(_a, ["className", "message", "children", "icon", "position", "alignment", "open"]);
    var tooltip = useContext(ThemeContext).theme.tooltip;
    var contentStyle = classNames(tooltip.content, className);
    var tooltipStyle = classNames(open && tooltip.open, tooltip.position[position]);
    var directionStyle = classNames(tooltip.direction[position], tooltip.alignment[alignment][position]);
    var iconStyle = tooltip.icon;
    return (_jsxs("div", __assign({ className: contentStyle, role: "tooltip" }, props, { ref: ref, "data-tip": message }, { children: [children, _jsxs("div", __assign({ className: tooltipStyle, "aria-label": 'Tooltip-container' }, { children: [_jsx("span", { className: directionStyle, "aria-label": 'Tooltip-direction' }), _jsxs("div", __assign({ className: 'inline-flex items-center' }, { children: [icon && _jsx(AptiveIcon, { icon: icon, className: iconStyle, isFilled: true }), _jsx("div", { children: message })] }))] }))] })));
});
export default Tooltip;
