import React from "react";

const Input = ({ label, required, className, readonly, ...props }) => {
  return (
    <>
      <div className="label-sm">
        {label}
        {required && <span className="text-danger">*</span>}
      </div>
      <div className="inputWrapper">
        <input {...props} className="form-control" />
      </div>
    </>
  );
};

export default Input;
