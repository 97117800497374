import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import YouTube from "react-youtube";
import { isMobile } from "react-device-detect";

import { stepAtom } from "../../atoms/step";
import CustomerSummary from "./CustomerSummary/CustomerSummary";
import ContactSupport from "../../components/ContactSupport";
import OrderSummary from "./OrderSummary";

export default function OrderConfirmation() {
  const step = useRecoilValue(stepAtom);
  
  useEffect(() => {    
    // GTM event
    if(process.env.NODE_ENV === "production") {
      window.dataLayer.push({
        event: 'service_contact_info'
      });
    }
  }, []);

  return (
    <div className={`rounded-2 order-confirmation-component${isMobile ? "-mobile" : " bg-white p-4"}`}>
      {isMobile ? (
        <CustomerSummary className={`customer-summary${!isMobile ? " row" : ""}`} step={step} />
      ) : (
        <div className="px-0">
          <OrderSummary isConfirmation={true} />
        </div>
      )}
      <div className="youtube">
        <div className="youtube-title">
          Learn more about your new Aptive pest plan.{" "}
          <a href="https://www.aptive.us/pro" target="_blank" rel="noreferrer">
            Check out our welcome brochure!
          </a>
        </div>
        <YouTube videoId="LOOmxxeM-ns" />
      </div>

      <ContactSupport message="Need additional help? Reach out to our support team at 1-844-422-6619." />
    </div>
  );
}
