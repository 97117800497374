import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";

import { stepAtom } from "../../../atoms/step";
import SubTitle from "../../../components/SubTitle";
import WeekSchedule from "../../../components/WeekSchedule";
import { formatDate } from "../../../helpers/formatDate";
import { formatDateNotYear } from "../../../helpers/formatDateNotYear";
import { getWeekdayName } from "../../../helpers/getWeekday";
import OrderSummary from "../OrderSummary";
import { isMobile } from "react-device-detect";

export default function ScheduleAppointment({
  customer,
  autoBookSpot,
  isLoadingSpots,
  isSelectingDefaultSpot,
  availableSpots,
  unavailableSpots,
  handleSelectSpot,
  selectedSpot,
  handleConfirmAppointment,
  loading,
  errorMessage,
  appeaseMessageDuration = 10000,
}) {
  const [step, setStep] = useRecoilState(stepAtom);
  const [isLoadingMessage, setLoadingMessage] = useState(true);
  const [reschedule, setReschedule] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingMessage(false);
    }, appeaseMessageDuration);

    return () => clearTimeout(timer);
  }, []);

  const handleConfirm = () => {
    const sameSpot = customer?.selectedSpot?.window === selectedSpot.window && customer?.selectedSpot?.date === selectedSpot.date;
    if (autoBookSpot && sameSpot) {
      setStep(step + 1);
    } else {
      handleConfirmAppointment();
    }
  };
  return (
    <div className={`schedule-component ${!isMobile && "py-5 px-3"}`}>
      {reschedule && (
        <div className="title-section mt-3">
          {!isMobile && (
            <SubTitle className="px-0 mb-4 fst-italic subtitle-sm text-center">
              Below are the available times within the next 2 weeks. Please select a time that works best for you.
            </SubTitle>
          )}
          <div className="mb-2 title subtitle">
            <SubTitle className="px-0 mb-1 subtitle text-center">Your Current Appointment:</SubTitle>
            {formatDate(customer?.selectedSpot?.date)} {customer?.selectedSpot?.window}
          </div>
        </div>
      )}

      <div>
        {isLoadingSpots || isSelectingDefaultSpot ? (
          isLoadingMessage ? (
            <div className="loading-msg mt-2" data-testid="loading-msg">
              <p>
                Please wait while we <span>{isLoadingSpots ? "search for available appointments" : "select the nearest for you"}</span>.
              </p>
              <i className="fa fa-circle-o-notch fa-spin fa-fw" />
            </div>
          ) : (
            <div className="loading-msg mt-2" data-testid="loading-msg">
              <p>
                We are still loading the available appointment times, <span>thanks for your patience.</span>
              </p>
              <i className="fa fa-circle-o-notch fa-spin fa-fw" />
            </div>
          )
        ) : (
          <>
            {customer?.selectedSpot && (
              <div className="title-section">
                {!reschedule && (
                  <>
                    <div className="subtitle my-2">Your Appointment Has Been Scheduled for:</div>
                    <div className="title mb-1">
                      {formatDate(customer?.selectedSpot?.date)} {customer?.selectedSpot?.window}
                    </div>
                    <SubTitle dataTestId="sub-title" className="autobook-subtitle subtitle-sm fst-italic text-center mb-3">
                      {autoBookSpot && `If this time doesn't suit you, please reschedule your appointment.`}
                    </SubTitle>
                  </>
                )}

                {!autoBookSpot && (
                  <>
                    <div className="title">Schedule your Initial Appointment</div>
                  </>
                )}
              </div>
            )}
            {!reschedule && (
              <div className="mx-auto">
                <OrderSummary testid="customer-summary" step={step} isScheduleStep={true} />
              </div>
            )}
            {!isLoadingSpots && availableSpots?.length === 0 && (
              <SubTitle dataTestId="no-available-spots">
                We are having difficulty displaying available appointments. Please call 1-844-422-6619 to get an appointment scheduled.
              </SubTitle>
            )}

            {errorMessage && (
              <div className="my-3">
                <SubTitle dataTestId="error-message">
                  {errorMessage || "The appointment you selected is no longer available. Please choose another time"}
                </SubTitle>
              </div>
            )}

            {(reschedule || !autoBookSpot) && (
              <div className={`${!isMobile && "d-flex justify-content-center"}`}>
                {availableSpots?.map((week, index) => (
                  <WeekSchedule
                    testid="week-schedule"
                    key={index}
                    week={week}
                    isThisWeek={index === 0}
                    unavailableSpots={unavailableSpots}
                    onSelectSpot={handleSelectSpot}
                    selectedSpot={selectedSpot}
                  />
                ))}
              </div>
            )}
          </>
        )}

        <div className="d-block mt-3 pb-2">
          {!isLoadingSpots && availableSpots?.length > 0 && (
            <div className="contact-btns-right mb-2">
              <button
                className={`button ${loading ? "loading" : ""} d-block p-1 ${!isMobile && "w-50 px-5 mx-auto"}`}
                disabled={!selectedSpot?.spotID}
                onClick={() => handleConfirm()}
                data-testid="confirm-button"
              >
                {loading && <i className="fa fa-circle-o-notch fa-spin fa-fw" />}
                {!loading && reschedule ? (
                  <>
                    <div className="confirm-btn">Confirm New Appointment:</div>
                    <div className="selected-date fst-italic">
                      {selectedSpot &&
                        getWeekdayName(selectedSpot?.date) +
                          ", " +
                          formatDateNotYear(selectedSpot?.date, "m-do") +
                          " in the " +
                          selectedSpot?.window}
                    </div>
                  </>
                ) : (
                  "View Order Summary"
                )}
              </button>
            </div>
          )}

          {reschedule
            ? autoBookSpot && (
                <div className="contact-btns-right">
                  <button
                    data-testid="keep-current-button"
                    onClick={() => setStep(step + 1)}
                    className={`button ${loading ? "loading" : ""} button-outline ${!isMobile && "w-50 px-5 mx-auto"}`}
                    disabled={loading}
                  >
                    Keep Current Appointment
                  </button>
                </div>
              )
            : customer?.selectedSpot && (
                <div className="contact-btns-right">
                  <button
                    data-testid="reschedule-button"
                    onClick={() => setReschedule(true)}
                    className={`button ${loading ? "loading" : ""} button-outline ${!isMobile && "w-50 px-4 mx-auto"}`}
                    disabled={loading}
                  >
                    Reschedule Appointment
                    {isLoadingSpots || isSelectingDefaultSpot ? <i className="fa fa-circle-o-notch fa-spin fa-fw" /> : ""}
                  </button>
                </div>
              )}
        </div>
      </div>
    </div>
  );
}
