import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useFeatureFlag } from "configcat-react";

import { customerAtom } from "../../atoms/customer";
import { stepAtom } from "../../atoms/step";
import {
  wasp,
  flea,
  fly,
  pantryPest,
  rodent,
  snail,
  mosquito,
  cockroach,
  greenCircleCheck,
  blackCirclePlus
} from "../../icons";

import { PREMIUM_SERVICE_ID, PRO_SERVICE_ID } from "../../constant";
import { isMobile } from "react-device-detect";
import { useRef } from "react";

// TODO - get all icons entered
const iconHash = {
  "Accessory Structure": "",
  "De-web & Treat 2-car+ Garage": '', 
  "De-web & Treat 3-car+ Garage": '',
  "German Roach": cockroach,
  "Indoor Fly Trap Service": fly,
  "Mosquitoes": mosquito,
  "Outdoor Rodent (includes voles)": rodent,
  "Outdoor Wasp Trap Service": wasp,
  "Pantry Pests": pantryPest,
  "Patio": '',
  "Perimeter Fence": '',
  "Raised Deck": '',
  "Snail/Slug/Aphid": snail,
  "Swingset/Playground": '',
  "Wood Shed": '',
}

const defaultProPlanAddons = [
  {
    icon: wasp,
    label: 'Outdoor wasp traps',
    subLabel: '',
    included: true,
  },
  {
    icon: flea,
    label: 'Fleas & ticks',
    subLabel: '(excludes NY)',
    included: true,
  },
  {
    icon: fly,
    label: 'Indoor fly traps',
    included: true,
  },
  {
    icon: pantryPest,
    label: 'Pantry Pests',
    included: true,
  },
  {
    icon: rodent,
    label: 'Rodents (exterior)',
    included: true,
  },
  {
    icon: snail,
    label: 'Snails/Slugs/Aphids',
    included: true,
  },
  {
    icon: mosquito,
    label: 'Mosquito',
    subLabel: '$199 initial & $99 / per service',
    included: false,
  },
  {
    icon: cockroach,
    label: 'German Cockroach',
    subLabel: '$399 initial & $99 / per service',
    included: false,
  },
];

const defaultPremiumPlanAddons = [
  {
    icon: wasp,
    label: 'Outdoor wasp traps',
    subLabel: '',
    included: true,
  },
  {
    icon: flea,
    label: 'Fleas & ticks',
    subLabel: '(excludes NY)',
    included: true,
  },
  {
    icon: fly,
    label: 'Indoor fly traps',
    included: true,
  },
  {
    icon: pantryPest,
    label: 'Pantry Pests',
    included: true,
  },
  {
    icon: rodent,
    label: 'Rodents (exterior)',
    included: true,
  },
  {
    icon: snail,
    label: 'Snails/Slugs/Aphids',
    included: true,
  },
  {
    icon: mosquito,
    label: 'Mosquito',
    subLabel: '$199 initial & $99 / per service',
    included: true,
  },
  {
    icon: cockroach,
    label: 'German Cockroach',
    subLabel: '$399 initial & $99 / per service',
    included: false,
  },
];

export default function SelectPlan() {
  const { value: usePlanBuilderAddons, loading: usePlanBuilderAddonsLoading } = useFeatureFlag("usePlanBuilderAddons", false);
  const [step, setStep] = useRecoilState(stepAtom);
  const [customer, setCustomer] = useRecoilState(customerAtom);
  const [proPlanAddons, setProPlanAddons] = useState(defaultProPlanAddons);
  const [premiumPlanAddons, setPremiumPlanAddons] = useState(defaultPremiumPlanAddons);
  
  useEffect(() => {
    if(usePlanBuilderAddons) {
      const proAddons = buildAddons(PRO_SERVICE_ID);      
      const premiumAddons = buildAddons(PREMIUM_SERVICE_ID);
      setProPlanAddons(proAddons);
      setPremiumPlanAddons(premiumAddons);
    }
  }, [usePlanBuilderAddons, usePlanBuilderAddonsLoading]);

  // Will not include row is all plans do not include the addon
  const includePlanRow = (row) => {        
    let include = false;
    Object.keys(row.isAvailable).forEach((planKey) => {
      if(row.isAvailable[planKey]) {
        include = true;
      }
    })                

    return include;
  }

  const buildAddons = (planId) => {
    let planRows = [];
    Object.keys(customer.addons).forEach((key) => {
      let include = includePlanRow(customer.addons[key]);
      
      if(include) {
        const addon = customer.addons[key];
        const addonRow = {
          icon: iconHash[key],
          label: key,              
          included: addon.isAvailable[planId],
        }
        planRows.push(addonRow);
      }
    });

    return planRows;
  }

  const handleSelectPlan = planNumber => {
    // GTM event
    if(process.env.NODE_ENV === "production") {
      window.dataLayer.push({
        event: 'choose_a_plan'
      });
    }

    setCustomer({ ...customer, planNumber });
    setStep(step + 1);
  };

  const proRef = useRef(null);
  const preRef = useRef(null);

  const scrollToPro = () => {
    proRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
  };
  const scrollToPre = () => {
    preRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
  };  

  const createPlanCard = (
    planId,
    ref,
    planName,
    color,
    initialPrice,
    planPrice,
    popular,
    addons,
  ) => {
    return (
      <div className={`${isMobile ? "mb-2 mx-3 border border-1" : "col-5 p-0"} pro shadow-sm`} ref={ref} id="pro">
        <div style={{backgroundColor: `#${color}`}} className="pro-row justify-content-center pro-plus-bg rounded-top-4 p-3 position-relative">
          <h2 className="pb-0 text-white p-0" data-testid={`${planId}-title`}>
            {planName}
          </h2>
          {popular && <div className="popular-badge text-uppercase position-absolute bg-plan">Popular</div>}
        </div>

        <div className={'bg-white'}>
          <div className="pro-row">
            <div className="col-6">
              <h2 className="price mb-0 mt-1">${initialPrice}</h2>
              <p className="mb-2 price-desc">Initial Treatment</p>
            </div>
            <div className="col-6 bg-plan ">
              <h2 className="price mb-0 mt-1">${planPrice}</h2>
              <p className="mb-2">Per Service</p>
            </div>
          </div>
          <div className="bg-white mx-5 my-3">
            <button
              className="button button-plan reh-choose-plan"
              data-type="selectProPlus"
              id={`button-plan-${planId}`}
              onClick={() => handleSelectPlan(planId)}              
              data-testid={`button-plan-${planId}`}
            >
              Get Started
            </button>
          </div>
          <div className="bg-plan">
            {
              addons.map((thisAddon, idx) => {
                return (
                  <div key={`${planName}-addon-${idx}`} className={`${!thisAddon.included && 'bg-white'} pro-row p-3 align-items-center`}>
                    <div className="d-flex align-items-center">
                      {thisAddon.icon}
                      <span className="plan-item">{thisAddon.label}</span>
                    </div>
                    {thisAddon.included ? greenCircleCheck : blackCirclePlus }
                  </div>
                );
              })
            }

            <div className="mx-4 border-bottom separate"></div>

            <div className="pro-row bg-white p-3 align-items-baseline">
              <div className="d-flex">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-2">
                  <g clipPath="url(#clip0_1322_2601)">
                    <path
                      d="M21 3H18V1H16V7H18V5H21V20H3V5H5V3H3C1.896 3 1 3.895 1 5V20C1 21.104 1.896 22 3 22H21C22.104 22 23 21.104 23 20V5C23 3.895 22.104 3 21 3Z"
                      fill="black"
                    />
                    <path d="M8 5H15V3H8V1H6V7H8V5Z" fill="black" />
                    <path d="M9 9H5V13H9V9Z" fill="black" />
                    <path d="M14 9H10V13H14V9Z" fill="black" />
                    <path d="M19 9H15V13H19V9Z" fill="black" />
                    <path d="M9 14H5V18H9V14Z" fill="black" />
                    <path d="M14 14H10V18H14V14Z" fill="black" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1322_2601">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <div className="plan-item">
                  Treatment Frequency
                  <p className="m-0 fst-normal">Apr - Oct: Every 30-60 days</p>
                  <p className="m-0 fst-normal">Nov - Mar: 50 -80 days</p>
                </div>
              </div>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-2">
                <g clipPath="url(#clip0_1322_2531)">
                  <path
                    d="M12 2.00098C6.486 2.00098 2 6.48698 2 12.001C2 17.515 6.486 22.001 12 22.001C17.514 22.001 22 17.515 22 12.001C22 6.48698 17.514 2.00098 12 2.00098ZM12 20.001C7.589 20.001 4 16.412 4 12.001C4 7.58998 7.589 4.00098 12 4.00098C16.411 4.00098 20 7.58998 20 12.001C20 16.412 16.411 20.001 12 20.001Z"
                    fill="black"
                  />
                  <path d="M13 7.00098H11V11.001H7V13.001H11V17.001H13V13.001H17V11.001H13V7.00098Z" fill="black" />
                </g>
                <defs>
                  <clipPath id="clip0_1322_2531">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <div className="bg-plan px-5 py-3 rounded-bottom-4">
          <button
            className="button button-plan reh-choose-plan"
            data-type="selectProPlus"
            id="button-plan-pro"
            onClick={() => handleSelectPlan(PRO_SERVICE_ID)}            
          >
            Get Started
          </button>
        </div>           
      </div>
    )
  }

  return (
    <>
      {isMobile &&
        <div className="align-items-center d-flex flex justify-content-end px-3 mt-n4 pb-3" data-testid="select-plan">
          <div>
            <span onClick={scrollToPro} className="fst-italic text-decoration-underline text-primary">
              Pro
            </span>
            <span className="mx-1">or</span>
            <span onClick={scrollToPre} className="fst-italic text-decoration-underline text-primary">
              Premium
            </span>
          </div>
        </div>
      }

      <div className={`${isMobile ? "plan-component-mobile" : "row plan-component"}`}>      
        {createPlanCard(PRO_SERVICE_ID, proRef, "Pro", '344C38', customer.prices?.proPlusInitial, customer.prices?.proPlusPlan, true, proPlanAddons)}
        {createPlanCard(PREMIUM_SERVICE_ID, preRef, "Premium", '0D210F',customer.prices?.premiumInitial, customer.prices?.premiumPlan, false, premiumPlanAddons)}          

        {isMobile &&
          <div className="flex top mx-3">
            <span
              className="fst-italic text-decoration-underline text-primary"
              onClick={e => {
                e.preventDefault();
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1322_2760)">
                  <path
                    d="M13 20.0001V7.41406L15.293 9.70706L16.707 8.29306L12 3.58606L7.29297 8.29306L8.70697 9.70706L11 7.41406V20.0001H13Z"
                    fill="#005CEA"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1322_2760">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Top
            </span>
          </div>
        }

        {isMobile ?         
          <div className="support-text-mobile m-0 px-3 py-2">*Frequency of treatments will vary depending on time of year and other factors.</div>        
        :             
          <div className="support-text col-10">*Frequency of treatments will vary depending on time of year and other factors.</div>                    
        }
      </div>
    </>
  );
}
