import { useRecoilState } from "recoil";
import { customerAtom } from "../../atoms/customer";
import { stepAtom } from "../../atoms/step";

import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { useState, useMemo } from "react";
import CustomerSummaryInfo from "../../components/CustomerSummaryInfo";
import ContactSupport from "../../components/ContactSupport";

const PHONE_USSA_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
const EMAIL_REGEX = /^[^\s@]+@\w+\.[^\s@]+$/;
const EMAIL_ERROR_MESSAGE = "Please enter a valid Email";

export default function ContactInfo() {
  const [step, setStep] = useRecoilState(stepAtom);
  const [customer, setCustomer] = useRecoilState(customerAtom);
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const onChangeFirstName = e => {
    setFirstName(e.target.value);
  };

  const onChangeLastName = e => {
    setLastName(e.target.value);
  };

  const onChangeEmailName = e => {
    setEmail(e.target.value);
    setEmailError(e.target.value.length === 0 || e.target.value.match(EMAIL_REGEX) === null ? EMAIL_ERROR_MESSAGE : "");
  };

  const onChangePhone = e => {
    setPhone(e.target.value);
    setPhoneError(
      e.target.value.length !== 0 && e.target.value.match(PHONE_USSA_REGEX) === null ? "Please enter a valid USA phone number" : ""
    );
  };

  const formInvalid = useMemo(
    () => firstName === "" || lastName === "" || phone === "" || email === "" || emailError !== "" || phoneError !== "",
    [firstName, lastName, phone, email, emailError, phoneError]
  );

  const onHandleSubmit = async e => {    
    e.preventDefault();
    e.stopPropagation();

    // GTM event
    if(process.env.NODE_ENV === "production") {
      window.dataLayer.push({
        event: 'service_contact_info'
      });
    }

    const cleanPhoneNumber = phone.replace(/\D/g, "").slice(-10);

    if (!formInvalid) {
      setLoading(true);
      setCustomer({
        ...customer,
        firstName,
        lastName,
        phone,
        cleanPhoneNumber,
        email,
      });
      setStep(step + 1);
      setLoading(false);
    }
  };

  return (
    <div className="step step-3">
      <Title>Thanks for choosing Aptive!</Title>
      <SubTitle>All of our plans include unlimited customer support and spot treatments.</SubTitle>

      <form className="container" onSubmit={onHandleSubmit}>
        <CustomerSummaryInfo step={step} />

        <div className="contact">
          <div className="contact-title">About You / Contact Info</div>
          <div className="contact-title-note">
            Fields with (<span className="contact-input-required">*</span>) are required
          </div>
          <div className="contact-form">
            <div className="contact-input">
              <Input
                data-testid="contact-info-firstname"
                required
                label="First"
                value={firstName}
                onChange={onChangeFirstName}
                type="text"
              />
            </div>
            <div className="contact-input">
              <Input data-testid="contact-info-lastname" required label="Last" value={lastName} onChange={onChangeLastName} type="text" />
            </div>
            <div className="contact-input">
              <Input data-testid="contact-info-phone" required label="Phone" value={phone} onChange={onChangePhone} type="text" />
              {phoneError.length !== 0 && <div className="contact-input-error">{phoneError}</div>}
            </div>
            <div className="contact-input">
              <Input data-testid="contact-info-email" required label="Email" value={email} onChange={onChangeEmailName} type="text" />
              {emailError.length !== 0 && <div className="contact-input-error">{emailError}</div>}
            </div>
          </div>
        </div>

        <div className="contact-terms">
          By clicking {"Submit"}, I authorize Aptive to call me and/or text me with offers about its services at the phone number above,
          using an autodialer or prerecorded messages. Consent not required for purchase. Message and data rates may apply. Promotion is
          subject to local service pricing, and may not apply to some services. For information about how we collect and use personal
          information, please read our{" "}
          <a href="https://goaptive.com/privacy-policy" rel="noreferrer" target="_blank">
            Privacy Policy
          </a>
          .
        </div>

        <div className="contact-btns">
          <div className="contact-btns-left">
            <Button
              testId="contact-info-back"
              type="button"
              onClick={() => setStep(step - 1)}
              className="contact-back-btn"
              icon={<i className="fa fa-angle-left" />}
            />
          </div>
          <div className="contact-btns-right">
            <Button testId="contact-info-submit" isLoading={loading} type="submit" disabled={formInvalid} label="Continue to Payment" />
          </div>
        </div>
      </form>
      <ContactSupport />
    </div>
  );
}
