import { useState, useMemo, useRef, useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import { useFeatureFlag } from "configcat-react";
import { useRecoilState } from "recoil";
import { isMobile } from "react-device-detect";
import { HOST_NAME } from "../../constant";
import { customerAtom } from "../../atoms/customer";
import { stepAtom } from "../../atoms/step";
import Button from "../../components/Button";
import Input from "../../components/Input";
import useCheckServiceability from "../../hook/useCheckServiceability";
import { extractAddressShortName, extractAddressLongName } from "../../helpers/placeHelper";

const PHONE_USSA_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
const EMAIL_REGEX = /^[^\s@]+@\w+\.[^\s@]+$/;
const EMAIL_ERROR_MESSAGE = "Please enter a valid Email";

const MESSAGE = "Service is not available in your area";
const INVALID_ADDRESS_MESSAGE = "Please enter a valid address.";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || "AIzaSyCBUicJb-ooCWzodi9ztVYFMwjDT_FKyj8";
Geocode.setApiKey(GOOGLE_API_KEY);

const getAddressInfo = address => ({
  address: extractAddressShortName(address, "street_number") + " " + extractAddressShortName(address, "route"),
  city: extractAddressLongName(address, "locality"),
  state: extractAddressShortName(address, "administrative_area_level_1"),
  county: extractAddressShortName(address, "administrative_area_level_2"),
  country: extractAddressLongName(address, "country"),
  postal_code: extractAddressLongName(address, "postal_code"),  
});

const ServiceCheck = ({ showMessage }) => {
  const { checkServiceability } = useCheckServiceability();
  const [customer, setCustomer] = useRecoilState(customerAtom);
  const [step, setStep] = useRecoilState(stepAtom);
  const { value: isCouponCodeEnabled } = useFeatureFlag("isCouponCodeEnabled", false);

  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [place, setPlace] = useState();
  const [message, setMessage] = useState(showMessage);
  const [extraData, setExtraData] = useState();
  const [enableBtn, setEnableBtn] = useState(false);
  const [discountCode, setDiscountCode] = useState(customer?.discount_code);
  const [referralCode, setReferralCode] = useState("");
  const [localPostCode, setLocalPostCode] = useState("");
  const [isDoneUpdateExtradata, setIsDoneUpdateExtradata] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setDiscountCode(customer?.lms?.discountCode || "");
  }, [customer]);

  useEffect(() => {
    if (inputRef.current && customer.formatted_address) {
      inputRef.current.value = customer.formatted_address;
    }
  }, [customer.formatted_address]);

  useEffect(() => {
    if(step === 0 && customer.address_components) {
      onGetAddress(customer);
    }

    customer?.firstName && setFirstName(customer.firstName);
    customer?.lastName && setLastName(customer.lastName);
    customer?.phone && setPhone(customer.phone);
    customer?.email && setEmail(customer.email);

  }, [step]);

  const onChangeFirstName = e => {
    setFirstName(e.target.value);
  };

  const onChangeLastName = e => {
    setLastName(e.target.value);
  };

  const onChangeEmailName = e => {
    setEmail(e.target.value);
    setEmailError(e.target.value.length === 0 || e.target.value.match(EMAIL_REGEX) === null ? EMAIL_ERROR_MESSAGE : "");
  };

  const onChangePhone = e => {
    setPhone(e.target.value);
    setPhoneError(
      e.target.value.length !== 0 && e.target.value.match(PHONE_USSA_REGEX) === null ? "Please enter a valid USA phone number" : ""
    );
  };

  const formInvalid = useMemo(
    () => firstName === "" || lastName === "" || phone === "" || email === "" || emailError !== "" || phoneError !== "",
    [firstName, lastName, phone, email, emailError, phoneError]
  );

  const onHandleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    const cleanPhoneNumber = phone.replace(/\D/g, "").slice(-10);

    if (message || !place) return;
    if (!formInvalid) {
      setLoading(true);
      setCustomer({
        ...customer,
        ...place,
        ...extraData,
        step: step + 1,
        discount_code: discountCode,
        isServiceable: true,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        cleanPhoneNumber: cleanPhoneNumber,
        email: email,
      });
      
      // GTM event
      if(process.env.NODE_ENV === "production") {
        window.dataLayer.push({
          event: 'service_contact_info'
        });
      }

      setStep(1);
      setLoading(false);
    }
  };

  const onDiscountBlur = async () => {
    setEnableBtn(false);

    try {
      const res = await checkServiceability(localPostCode, discountCode);

      setExtraData({
        ...extraData,
        prices: res?.data?.prices,
      });
      setLocalPostCode(localPostCode);
      setMessage(res?.data?.isServiceable ? "" : MESSAGE);
      setEnableBtn(!!res?.data?.isServiceable);
    } catch (e) {
      setEnableBtn(false);
      setMessage(MESSAGE);
    }
    setIsDoneUpdateExtradata(false);
  };

  const onGetAddress = async val => {
    const postalCode = extractAddressLongName(val.address_components, "postal_code");

    // Validate the address components array to ensure it has the required fields
    if (!isValidAddressArray(val.address_components)) {
      setMessage(INVALID_ADDRESS_MESSAGE);
      setEnableBtn(false);
      setCustomer({ ...customer, isServiceable: false, place: val, step: 0 });
      return;
    }

    setPlace(val);
    if (!postalCode) {
      setMessage(MESSAGE);
      setEnableBtn(false);
      return;
    }
    setLoading(true);
    const addressInfo = getAddressInfo(val.address_components);
    try {
      const res = await checkServiceability(postalCode, discountCode);
      setLocalPostCode(postalCode);
      const newExtraData = {
        officeId: res?.data?.officeId,
        prices: res?.data?.prices,
        addons: res?.data?.addons,
        frequency: res?.data?.frequency,
        ...addressInfo,
      };
      setExtraData(newExtraData);
      const isServiceable = res?.data?.isServiceable;
      setMessage(isServiceable ? "" : MESSAGE);
      setEnableBtn(!!isServiceable);
      if (!isServiceable) {
        setCustomer({ ...customer, isServiceable: false, place: place, extraData: newExtraData, step: 0 });
      }
    } catch (e) {
      setEnableBtn(false);
      setCustomer({ ...customer, isServiceable: false, place: val, step: 0 });
      setMessage(MESSAGE);
    }
    setLoading(false);
  };

  const handleAddressChange = e => {
    Geocode.fromAddress(e.target.value).then(
      response => {
        onGetAddress(response.results[0]);
      },
      error => {
        setMessage(INVALID_ADDRESS_MESSAGE);
        setEnableBtn(false);
      }
    );
  };

  const isValidAddressArray = addressComponents => {
    const requiredTypes = ["street_number", "locality"];
    const typesPresent = addressComponents.map(component => component.types).flat();
    return requiredTypes.every(type => typesPresent.includes(type));
  };

  return (
    <div className='row'>
      <div className={`p-3 rounded-2 contact-component${isMobile ? "-mobile" : " col-6 "}`}>
        <form className="py-2" onSubmit={onHandleSubmit}>
          <div className={`contact ${isMobile && 'pt-2'}` }>
            <div className="row">
              <div className="col-sm-6 col-12 mb-2">
                <Input
                  data-testid="contact-info-firstname"
                  required
                  label="First"
                  value={firstName}
                  onChange={onChangeFirstName}
                  type="text"
                />
              </div>
              <div className="col-sm-6 col-12 mb-2">
                <Input
                  data-testid="contact-info-lastname"
                  required
                  label="Last"
                  value={lastName}
                  onChange={onChangeLastName}
                  type="text"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-12 mb-2">
                <Input data-testid="contact-info-phone" required label="Phone" value={phone} onChange={onChangePhone} type="text" />
                {phoneError.length !== 0 && <div className="text-danger">{phoneError}</div>}
              </div>
              <div className="col-sm-6 col-12 mb-2">
                <Input data-testid="contact-info-email" required label="Email" value={email} onChange={onChangeEmailName} type="text" />
                {emailError.length !== 0 && <div className="text-danger">{emailError}</div>}
              </div>
            </div>
            <div className="p-0">
              <div className="form-container p-0 border-0 shadow-none m-0">
                <div className="mb-2">
                  <div className="label-sm">
                    Address<span className="text-danger">*</span>
                  </div>
                  <Autocomplete
                    data-testid="autocomplete"
                    options={{
                      componentRestrictions: { country: "us" },
                      types: ["geocode", "establishment"],
                    }}
                    apiKey={GOOGLE_API_KEY}
                    onPlaceSelected={() => inputRef.current.blur()}
                    onBlur={handleAddressChange}
                    ref={inputRef}
                    language="en"
                    className="form-control mb-1"
                    placeholder=""
                  />
                </div>
                <div className={`${isMobile ? "description-sm" : "description"}`}>
                  <div>Why do we need your information?</div>
                  <div>Your address allows us to determine if you are in an area we service.</div>
                </div>
                <div className="row">
                  {isCouponCodeEnabled && (
                    <div className={`referral ${isMobile ? '' : 'col-6'}`}>
                      <div className="label-sm">Referral Code</div>
                      <input
                        value={referralCode}
                        onChange={e => setReferralCode(e.target.value)}
                        className="form-control mb-3"
                        type="text"
                        data-testid="referral-code"
                      />
                    </div>
                  )}
                  {isCouponCodeEnabled && (
                    <div className={`discount ${isMobile ? '' : 'col-6'}`}>
                      <div className="label-sm">Discount Code</div>
                      <input
                        value={discountCode}
                        onChange={e => setDiscountCode(e.target.value)}
                        onBlur={onDiscountBlur}
                        className="form-control mb-3"
                        type="text"
                        data-testid="discount-code"
                      />
                    </div>
                  )}
                </div>

                {message && <h4 className="text-danger">{message}</h4>}
              </div>
            </div>
            <div className="contact-btns m-3">
              <div className={`${isMobile && 'contact-btns-right'}`}>
                <Button
                  testId="contact-info-submit"
                  isLoading={loading || isDoneUpdateExtradata}
                  disabled={!enableBtn || formInvalid || isDoneUpdateExtradata}
                  label="Next: Select a Plan"
                  type="submit"
                />
              </div>
            </div>        
          </div>
        </form>
        <div className={`${isMobile ? "contact-terms-sm" : "contact-terms"}`}>
          By clicking {`"Next"`}, I authorize Aptive to call me and/or text me with offers about its services at the phone number above,
          using an autodialer or prerecorded messages. Consent not required for purchase. Message and data rates may apply. Promotion is
          subject to local service pricing, and may not apply to some services. For information about how we collect and use personal
          information, please read our{" "}
          <a href="https://goaptive.com/privacy-policy" rel="noreferrer" target="_blank">
            Privacy Policy
          </a>
          .
        </div>
      </div>
      {!isMobile && <img className={"col-6 rounded-4"} src={`${HOST_NAME}/images/serviceImage.png`} alt="Promo" />}
    </div>
  );
};

export default ServiceCheck;
