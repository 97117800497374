import { useRecoilState } from "recoil";
import { customerAtom } from "../../atoms/customer";
import { stepAtom } from "../../atoms/step";

import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import { useEffect, useState } from "react";
import CustomerSummaryInfo from "../../components/CustomerSummaryInfo";
import ContactSupport from "../../components/ContactSupport";
import PaymentForm from "../../components/PaymentForm";

import { useFeatureFlag } from "configcat-react";
import { apiRequest } from "../../api/apiRequest";
import { getFetchPaymentIframeUrl } from "../../api/apiEndpoints";

window.worldpayCallback = function () {
  return;
};

export default function PaymentInfoIframe() {
  const [customer, setCustomer] = useRecoilState(customerAtom);
  const [step, setStep] = useRecoilState(stepAtom);
  const { value: useLegacyPaymentProvider, loading: useLegacyPaymentProviderLoading } = useFeatureFlag("useLegacyPaymentProvider", true);

  const [iframeURL, setIframeURL] = useState("");
  const officeId = customer.officeId;

  const fetchIframe = async () => {
    try {
      const res = await apiRequest({
        url: getFetchPaymentIframeUrl(officeId),
      });
      setIframeURL(res?.data?.url);
    } catch {
      setIframeURL("");
    }
  };

  useEffect(() => {
    window.worldpayCallback = function (data) {
      // GTM event
      if(process.env.NODE_ENV === "production") {
        window.dataLayer.push({
          event: 'payment_info'
        });
      }
      
      setCustomer({
        ...customer,
        worldpay: data,
      });
      setStep(step + 1);
    };

    if (!iframeURL) {
      fetchIframe();
    }
  }, []);

  // Tokenex Only
  const onSubmit = data => {
    // GTM event
    if(process.env.NODE_ENV === "production") {
      window.dataLayer.push({
        event: 'payment_info'
      });
    }
    
    setCustomer({
      ...customer,
      payment_data: data,
      use_aptive_payment_service: true,
    });     
    setStep(step + 1);
  };

  return (
    <div className="step step-4">
      <Title>Payment Information</Title>
      <SubTitle>You will not be charged today. Payment will be due following each treatment.</SubTitle>

      <div className="container">
        <div className="summary">{customer && <CustomerSummaryInfo step={step} />}</div>

        {!useLegacyPaymentProviderLoading &&
          (useLegacyPaymentProvider ? (
            <>
              {iframeURL ? (
                <iframe id="payframe" src={iframeURL} title="Payment Iframe" width="100%" height="350px" />
              ) : (
                <div style={{ height: "350px" }}></div>
              )}
            </>
          ) : (
            <PaymentForm onSubmit={onSubmit} />
          ))}
      </div>
      <ContactSupport />
    </div>
  );
}
