import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

export function formatDate(dateString) {
  return format(parseISO(dateString), "MMMM do, yyyy");
}

// These are not used anywhere so it is commented out until it is used.
/* function getNumberSuffix(number) {
  if (number >= 11 && number <= 13) {
    return "th";
  }

  const lastDigit = number % 10;
  switch (lastDigit) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
} */

// export function getAMPMFromSeconds(seconds) {
//   const parsedSeconds = parseInt(seconds, 10);

//   const hours = parsedSeconds / 3600;

//   const period = hours >= 12 ? "PM" : "AM";

//   return period;
// }
