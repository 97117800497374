import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { useFeatureFlag } from "configcat-react";
import { isMobile } from "react-device-detect";

import PaymentForm from "../../components/PaymentForm";
import { apiRequest } from "../../api/apiRequest";
import { getFetchPaymentIframeUrl } from "../../api/apiEndpoints";
import { customerAtom } from "../../atoms/customer";
import { stepAtom } from "../../atoms/step";


window.worldpayCallback = function () {
  return;
};

export default function PaymentInfoIframe() {
  const [customer, setCustomer] = useRecoilState(customerAtom);
  const [step, setStep] = useRecoilState(stepAtom);
  const { value: useLegacyPaymentProvider, loading: useLegacyPaymentProviderLoading } = useFeatureFlag("useLegacyPaymentProvider", true);

  const [iframeURL, setIframeURL] = useState("");
  const officeId = customer.officeId;

  const fetchIframe = async () => {
    try {
      const res = await apiRequest({
        url: getFetchPaymentIframeUrl(officeId),
      });
      setIframeURL(res?.data?.url);
    } catch {
      setIframeURL("");
    }
  };

  useEffect(() => {
    window.worldpayCallback = function (data) {      
      if(process.env.NODE_ENV === "production") {
        window.dataLayer.push({
          event: 'payment_info'
        });
      }

      setCustomer({
        ...customer,
        worldpay: data,
      });
      setStep(step + 1);
    };

    if (!iframeURL) {
      fetchIframe();
    }
  }, []);

  // Tokenex Only
  const onSubmit = data => {    
    if(process.env.NODE_ENV === "production") {
      window.dataLayer.push({
        event: 'payment_info'
      });
    }

    setCustomer({
      ...customer,
      payment_data: data,
      use_aptive_payment_service: true,
    });
    setStep(step + 1);
  };

  return (
    <>
      <div className={`payment-component px-3 rounded`}>
        <div className={`${!isMobile && "m-5 p-5"}`}>
          {!useLegacyPaymentProviderLoading &&
            (useLegacyPaymentProvider ? (
              <>
                {iframeURL ? (
                  <iframe id="payframe" src={iframeURL} title="Payment Iframe" width="100%" height="350px" />
                ) : (
                  <div style={{ height: "350px" }}></div>
                )}
              </>
            ) : (
              <PaymentForm onSubmit={onSubmit} />
            ))}
        </div>
      </div>
    </>
  );
}
