import { useRecoilState } from "recoil";
import { customerAtom } from "../../atoms/customer";
import { stepAtom } from "../../atoms/step";

import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import Button from "../../components/Button";
import CustomerSummaryInfo from "../../components/CustomerSummaryInfo";
import useSignup from "../../hook/useSignup";
import { useState } from "react";
import ContactSupport from "../../components/ContactSupport";
import useGetAvailableSpots from "../../hook/useGetAvailableSpots";

export default function OrderSummary({ setSpots }) {
  const [step, setStep] = useRecoilState(stepAtom);
  const [customer, setCustomer] = useRecoilState(customerAtom);
  const { signup } = useSignup();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("An error has occured");
  const { getData } = useGetAvailableSpots(customer.customerId, customer.accessToken, customer?.geometry?.location);

  const onHandlePlaceOrder = async () => {
    setLoading(true);
    const cleanPhoneNumber = customer.phone.replace(/\D/g, "").slice(-10);
    const payload = {
      customer: {
        firstName: customer.firstName,
        lastName: customer.lastName,
        address: customer.address,
        city: customer.city,
        state: customer.state,
        postalCode: customer.postal_code,
        phone: cleanPhoneNumber,
        email: customer.email,
        county: customer.county,
        latitude: customer.geometry.location.lat,
        longitude: customer.geometry.location.lng
      },
      subscription: {
        serviceId: customer.planNumber,
      },
      payment_data: {
        ...(customer.payment_data ? customer.payment_data : {}),
      },
      paymentProfile: {
        token: customer.worldpay?.PaymentAccountID,
      },
      use_promotional: customer.prices.promoPrices ? true : false,
      use_aptive_payment_service: customer.use_aptive_payment_service,
    };

    if (customer.discount_code) {
      payload.discount_code = customer.discount_code;
    }

    try {
      const res = await signup(payload);
      const newData = {
        ...customer,
        ...res?.data,
      };
      const callback = spots => {
        setCustomer({
          ...newData,
          spots,
          ...(!spots?.length && { getAvailableSpotsMessage: "Spots were not received" }),
        });
        setSpots(spots);
        setErrorMessage("");
        setStep(step + 1)
      };
      await getData({ signupRes: res?.data, callback });
    } catch (error) {
      setError(true);
      if (error.response.data.isDuplicate) {
        setErrorMessage("This customer account already exists.");
      } else {
        setErrorMessage("An error occured during the signup process.");
      }
      console.log(error);
    } finally {
      setLoading(false);
    }    
  };

  return (
    <div className="step step-5">
      <Title>Order Summary</Title>
      <SubTitle>All of our plans include unlimited customer support and spot treatments.</SubTitle>

      <div className="container">
        <CustomerSummaryInfo step={step} />
        <div className="contact-btns">
          <div className="contact-btns-right">
            <Button testId="order_summary-place_order" isLoading={loading} onClick={onHandlePlaceOrder} label="Place Your Order" />
          </div>
        </div>
        {error && (
          <div className="place-order-error-text">
            <div>{errorMessage}</div>
            <div>Please contact our support team at 1-855-642-8632 for more help.</div>
          </div>
        )}
        <div className="place-order-button-below-text">Payment will be collected after your initial appointment has been completed.</div>
      </div>
      <ContactSupport />
    </div>
  );
}
