import React from "react";
import { useRecoilValue } from "recoil";
import { customerAtom } from "./../../../atoms/customer";
import { formatDate } from "./../../../helpers/formatDate";
import { extractAddressShortName, extractAddressLongName } from "./../../../helpers/placeHelper";
import { PRO_SERVICE_ID, PREMIUM_SERVICE_ID } from "./../../../constant";


const formatPhoneNumber = phoneNumberString => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumberString;
};

const CustomerSummary = ({ testid, className }) => {
  const customer = useRecoilValue(customerAtom);
  const planName = () => {
    switch (customer.planNumber.toString()) {
      case PRO_SERVICE_ID.toString():
        return "Pro";
      case PREMIUM_SERVICE_ID.toString():
        return "Premium";
      default:
        return "";
    }
  };

  const getAddressInfo = address => ({
    address: extractAddressShortName(address, "street_number") + " " + extractAddressShortName(address, "route"),
    city: extractAddressLongName(address, "locality"),
    state: extractAddressShortName(address, "administrative_area_level_1"),
    country: extractAddressShortName(address, "country"),
    postal_code: extractAddressLongName(address, "postal_code"),
  });

  const createAddress = () => {
    const addressInfo = getAddressInfo(customer.address_components);
    
    return (
      <div>
        <div className="d-flex">
          <div>
            {addressInfo.address}
            
          </div>
        </div>
        <div>
          {addressInfo.city}, {addressInfo.state}{" "}          
        </div>
        <div>          
          {addressInfo.postal_code}
        </div>
        <div></div>
      </div>
    );
  };

  return (
    <div data-testid={testid} className={`${className ? className : "customer-summary"}`}>
      {customer?.firstName && (
        <div data-testid="about-contact" className="col address">
          <div className="title">About / Contact</div>
          <div className="content">
            <div className="d-flex flex-column">
              <span data-testid="customer-name">
                {customer.firstName} {customer.lastName}
              </span>
              <span className="text-decoration-underline">{customer.email}</span>
              <span>{formatPhoneNumber(customer.phone)}</span>
            </div>
          </div>
        </div>
      )}
      {customer?.address_components && (
        <div className="col address">
          <div className="title">Service Address</div>
          <div className="content" data-testid="customer-formatted-address">
            {createAddress()}
          </div>
        </div>
      )}
      {customer?.planNumber && (
        <div className="col address">
          <div className="title">Plan</div>
          <div className="content" data-testid="customer-plan-name">{`Aptive ${planName()}`}</div>
        </div>
      )}

      {customer?.worldpay && (
        <div data-testid="payment" className="col address">
          <div className="title">Payment</div>
          <div className="content" data-testid="customer-worldpay">
            {customer.firstName} {customer.lastName} **** **** **** {customer.worldpay.LastFour}
          </div>
        </div>
      )}

      {customer?.payment_data && (
        <div data-testid="payment" className="col address">
          <div className="title">Payment</div>
          <div className="content" data-testid="customer-payment-data">
            {customer.firstName} {customer.lastName} **** **** **** {customer.payment_data.lastFour}
          </div>
        </div>
      )}

      {customer.appointment && (
        <div data-testid="initial-appointment" className="col address">
          <div className="title">Initial Appointment</div>
          {customer.selectedSpot.date && customer.selectedSpot.window && (
            <div className="content" data-testid="customer-appointment">
              {formatDate(customer.selectedSpot.date)} {customer.selectedSpot.window}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomerSummary;
