import React, { useState } from "react";

import { useRecoilValue } from "recoil";
import { customerAtom } from "../../atoms/customer";
import { PREMIUM_SERVICE_ID, PRO_SERVICE_ID } from "../../constant";

export default function ExpressSelectPlan({ handleSelectPlan }) {
  const [selectedPlan, setSelectedPlan] = useState();
  const customer = useRecoilValue(customerAtom);

  const onSelect = plan => {
    handleSelectPlan(plan);
    setSelectedPlan(plan);

    // GTM event
    if(process.env.NODE_ENV === "production") {
      window.dataLayer.push({
        event: 'choose_a_plan'
      });
    }
  };

  return (
    <div className="step step-2">
      {/* <Title>Great! We can service your home!</Title> */}

      <section className="plan mt-2">
        <h2 className="main-title desktop mb-3">Choose your Aptive plan</h2>
        <div className="row plan-top mb-3">
          <div className="col-lg-4"></div>
          <div className="col-lg-4 col-md-12 mb-sm-0 mb-5">
            <div className="pro">
              <div className="pro-row">
                <h2 className="pb-0" data-testid="pro-plus-title">
                  Pro
                </h2>
                {selectedPlan === PRO_SERVICE_ID && (
                  <div className="selected" data-testid="pro-premium-selected">
                    SELECTED
                  </div>
                )}
              </div>

              <p data-testid="pro-plus-description">
                Treatments every 30-60 days from April to October. From November to March, treatments every 50-80 days.
              </p>
              <h2 className="price ">
                <span data-testid="pro-plus-initial" className="price-value">
                  {customer.prices?.promoPrices?.proPlusInitial ? (
                    <>
                      <div className="promo-price-wrapper">
                        <div className="price-wrapper">
                          <div className="promo-prices">
                            <div className="old-price">${customer.prices?.proPlusInitial}</div>
                            <div className="new-price">${customer.prices?.promoPrices?.proPlusInitial}</div>
                          </div>
                          <div className="online-promotion">ONLINE PROMOTION</div>
                        </div>
                        <div className="treatment-description">initial treatment</div>
                      </div>
                      +<br />
                    </>
                  ) : (
                    <>${customer.prices?.proPlusInitial}</>
                  )}
                </span>
                {customer.prices?.promoPrices?.proPlusInitial ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <span>initial treatment</span> <br />+<br />
                  </>
                )}

                {customer.prices?.promoPrices?.proPlusPlan ? (
                  <div className="promo-price-wrapper">
                    <div className="price-wrapper">
                      <div className="promo-prices">
                        <div className="old-price">${customer.prices?.proPlusPlan}</div>
                        <div className="new-price">${customer.prices?.promoPrices?.proPlusPlan}</div>
                      </div>
                    </div>
                    <div className="treatment-description">standard treatment</div>
                  </div>
                ) : (
                  <span className="standard-wrapper">
                    <span className="price-value" data-testid="pro-plus-plan">
                      ${customer.prices?.proPlusPlan}
                    </span>{" "}
                    <span>standard treatment</span>
                  </span>
                )}
              </h2>

              <button
                className={`button button-plan reh-choose-plan`}
                data-type="selectProPlus"
                id="button-plan-pro"
                onClick={() => onSelect(PRO_SERVICE_ID)}
                data-testid="pro-plus-get-started-btn-up-left"
                disabled={selectedPlan === PRO_SERVICE_ID}
              >
                {selectedPlan === PRO_SERVICE_ID ? "Selected" : "Choose"}
              </button>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 mb-3">
            <div className="pro-plus">
              <div className="pro-row">
                <h2 className="pb-0" data-testid="pro-premium-title">
                  Premium
                </h2>
                <div className="popular" data-testid="pro-premium-popular">
                  Most Popular
                </div>
                {selectedPlan === PREMIUM_SERVICE_ID && (
                  <div className="selected" data-testid="pro-premium-selected">
                    SELECTED
                  </div>
                )}
              </div>

              <p data-testid="pro-premium-description">
                Treatments every 20-40 days from April to October. From November to March, treatments every 50-80 days.
              </p>
              <h2 className="price">
                <span data-testid="premium-initial" className="price-value">
                  {customer.prices?.promoPrices?.premiumInitial ? (
                    <>
                      <div className="promo-price-wrapper">
                        <div className="price-wrapper">
                          <div className="promo-prices">
                            <div className="old-price premium">${customer.prices?.premiumInitial}</div>
                            <div className="new-price">${customer.prices?.promoPrices?.premiumInitial}</div>
                          </div>
                          <div className="online-promotion premium">ONLINE PROMOTION</div>
                        </div>
                        <div className="treatment-description">initial treatment</div>
                      </div>
                      +<br />
                    </>
                  ) : (
                    <>${customer.prices?.premiumInitial}</>
                  )}
                </span>
                {customer.prices?.promoPrices?.premiumInitial ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <span>initial treatment</span> <br />+<br />
                  </>
                )}

                {customer.prices?.promoPrices?.premiumPlan ? (
                  <div className="promo-price-wrapper">
                    <div className="price-wrapper">
                      <div className="promo-prices">
                        <div className="old-price premium">${customer.prices?.premiumPlan}</div>
                        <div className="new-price">${customer.prices?.promoPrices?.premiumPlan}</div>
                      </div>
                    </div>
                    <div className="treatment-description">standard treatment</div>
                  </div>
                ) : (
                  <span className="standard-wrapper">
                    <span className="price-value" data-testid="pro-plus-plan">
                      ${customer.prices?.premiumPlan}
                    </span>{" "}
                    <span>standard treatment</span>
                  </span>
                )}
              </h2>

              <button
                className={`button button-plan reh-choose-plan`}
                data-type="selectProPlus"
                id="button-plan-pro"
                onClick={() => onSelect(PREMIUM_SERVICE_ID)}
                data-testid="pro-plus-get-started-btn-up-right"
                disabled={selectedPlan === PREMIUM_SERVICE_ID}
              >
                {selectedPlan === PREMIUM_SERVICE_ID ? "Selected" : "Choose"}
              </button>
            </div>
          </div>
        </div>
        <div className="plan-row plan-bottom">
          <div className="plan-col">
            <div className="names p-2 py-0">
              <div data-testid="all-general">All General Pests*</div>
              <div data-testid="eaves">Eaves</div>
              <div data-testid="foundation">Foundation</div>
              <div data-testid="in-ex-terior">Interior &amp; Exterior</div>
              <div data-testid="re-serivces">Re-services at no cost</div>
              <div data-testid="indoor">Indoor fly traps</div>
              <div data-testid="outdoor">Outdoor wasp traps</div>
              <div data-testid="flea-tick">Fleas &amp; Ticks</div>
              <div data-testid="pantry">Pantry Pests</div>
              <div data-testid="rodents">Rodents (exterior)</div>
              <div data-testid="snails">Snails / Slugs / Aphids</div>
              <div data-testid="mosquito">Mosquito</div>
              <div data-testid="german">German Cockroach</div>
            </div>
          </div>

          <div className="plan-col">
            <div className="title mobile">Pro</div>
            <div className="includes">
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-close">-</span>
              </div>
              <div>
                <span className="et-pb-icon icon-close">-</span>
              </div>
              <div>
                <button
                  className="button button-plan-plus reh-choose-plan"
                  data-type="selectProPlus"
                  id="button-plan-pro"
                  onClick={() => onSelect(PRO_SERVICE_ID)}
                  data-testid="pro-plus-get-started-btn-down-left"
                  disabled={selectedPlan === PRO_SERVICE_ID}
                >
                  {selectedPlan === PRO_SERVICE_ID ? "Selected" : "Choose"}
                </button>
              </div>
            </div>
          </div>

          <div className="plan-col">
            <div className="title mobile fs-lg fs-sm">Premium</div>
            <div className="includes fs-sm fs-lg">
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-green"></span>
              </div>
              <div>
                <span className="et-pb-icon icon-close">-</span>
              </div>
              <div>
                <button
                  className="button button-plan-plus reh-choose-plan"
                  data-type="selectProPlus"
                  id="button-plan-pro"
                  onClick={() => onSelect(PREMIUM_SERVICE_ID)}
                  data-testid="pro-plus-get-started-btn-down-right"
                  disabled={selectedPlan === PREMIUM_SERVICE_ID}
                >
                  {selectedPlan === PREMIUM_SERVICE_ID ? "Selected" : "Choose"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div data-testid="disclaimer" className="disclaimer">
          <p>
            To be eligible for this offer, you must (1) live in a serviceable area, and (2) agree to a contract with a minimum term of one
            year. Only valid for new customers. Other terms and conditions apply.
          </p>
          {customer.prices?.promoPrices !== null && (
            <p>
              Limited time offer. Offer available to new customers only through online checkout. Offer is not available through other
              channels. Discount based on the standard Initial Treatment price of $399. Requires a minimum 12-month Service Agreement with a
              minimum of 4 Standard Treatments.
            </p>
          )}
        </div>
      </section>
    </div>
  );
}
