import React, { useEffect, useState, useRef } from "react";
import { useRecoilValue } from "recoil";
import { customerAtom } from "./../atoms/customer";

import { apiRequest } from "../api/apiRequest";
import { HOST_NAME, authTokenUrl, validateCreditCardUrl } from "../api/apiEndpoints";
import Button from "./Button";

const getCurrentTimestamp = () => {
  return new Date()
    .toISOString()
    .replace(/[^0-9]/g, "")
    .slice(0, -3);
};

const currentTimeStamp = getCurrentTimestamp();

const PaymentForm = ({ onSubmit }) => {
  const customer = useRecoilValue(customerAtom);
  const currentYear = new Date().getFullYear();
  const expirationYears = Array.from({ length: 10 }, (_, i) => currentYear + i);

  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [authKey, setAuthKey] = useState(null);
  const [tokenexIframe, setTokenexIframe] = useState(null);

  const [selectedMonth, setSelectedMonth] = useState("01");
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());

  const [errorMessage, setErrorMessage] = useState("");
  const [iframeErrorMessage, setIframeErrorMessage] = useState("");

  const tokenExIframeRef = useRef(null);

  const iframeConfig = {
    origin: HOST_NAME,
    timestamp: currentTimeStamp,
    tokenExID: "9008701043721125",
    tokenScheme: "PCI",
    authenticationKey: authKey,
    pci: true,
    enablePrettyFormat: true,
    styles: {
      base: "font-family: Helvetica, Arial, sans-serif;padding: 0 8px;border: 1px solid #ccc;;margin: 0;width: 100%;font-size: 16px;line-height: 30px;height: 35px;box-sizing: border-box;-moz-box-sizing: border-box; border-radius: 4px;",
      focus: "box-shadow: 0 0 6px 0 rgba(0, 132, 255, 0.5);border: 1px solid rgba(0, 132, 255, 0.5);outline: 0;",
      error: "box-shadow: 0 0 6px 0 rgba(224, 57, 57, 0.5);border: 1px solid rgba(224, 57, 57, 0.5);",
    },
  };

  const authToken = async () => {
    setIsIframeLoading(true);
    setIsLoading(true);

    const body = {
      timestamp: currentTimeStamp,
      origin: HOST_NAME,
    };
    try {
      const response = await apiRequest({
        url: authTokenUrl,
        method: "post",
        data: body,
      });

      setIframeErrorMessage("");
      setAuthKey(response.data.authentication_token);
    } catch (error) {
      const errors = error.response?.data?.errors;

      setIframeErrorMessage(
        errors?.[0].detail || errors?.[0].title || error?.toString() || "Cannot load the Iframe. Please refresh to try again."
      );
    } finally {
      setIsLoading(false);
      setIsIframeLoading(false);
    }
  };

  useEffect(() => {
    if (tokenExIframeRef.current && authKey && window.TokenEx) {
      setTimeout(() => {
        var iframe = new window.TokenEx.Iframe(tokenExIframeRef.current.id, iframeConfig);
        setTokenexIframe(iframe);
        iframe.load();
      }, 0);
    }
  }, [tokenExIframeRef, authKey, window.TokenEx]);

  useEffect(() => {
    if (!authKey) {
      authToken();
    }
  }, []);

  if (tokenexIframe) {
    tokenexIframe.on("tokenize", async function (data) {
      if (data) {
        data.expirationMonth = selectedMonth;
        data.expirationYear = selectedYear;

        const body = {
          token: data.token,
          expirationMonth: data.expirationMonth,
          expirationYear: data.expirationYear,
          officeId: customer.officeId,
        };

        try {
          const response = await apiRequest({
            url: validateCreditCardUrl,
            method: "post",
            data: body,
          });
          if (!response.data.is_valid) {
            setErrorMessage("Card validation failed. Please use a different card number.");
          } else {
            onSubmit(data);
          }
        } catch (error) {
          if (error.response?.data && !error.response.data.is_valid) {
            setErrorMessage("Card validation failed. Please use a different card number.");
          } else {
            setErrorMessage("Card validation failed. Please try again.");
          }
        } finally {
          setIsLoading(false);
        }
      }
    });

    tokenexIframe.on("validate", function (data) {
      if (!data.isValid) {
        if (data.validator === "format") {
          setErrorMessage("Invalid card format");
        } else if (data.validator === "required") {
          setErrorMessage("Card number is required");
        } else {
          setErrorMessage("Card validation failed");
        }
        setIsLoading(false);
      } else {
        setErrorMessage("");
        tokenexIframe.tokenize();
      }
    });
  }

  const tokenexValidate = () => {
    setIsLoading(true);
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    const selectedMonthInt = parseInt(selectedMonth, 10);
    const selectedYearInt = parseInt(selectedYear, 10);

    if (selectedYearInt < currentYear || (selectedYearInt === currentYear && selectedMonthInt < currentMonth)) {
      setErrorMessage("Card is expired");
      setIsLoading(false);
    } else {
      tokenexIframe.validate();
    }
  };

  return (
    <div className="payment-wrapper">
      <h3>Card Information</h3>

      <label htmlFor="tokenExIframeDiv" className="input-label">
        Card Number
      </label>
      {isIframeLoading && (
        <div className="loading-msg">
          <p>Loading card number input...</p>
          <i className="fa fa-circle-o-notch fa-spin fa-fw" />
        </div>
      )}
      {iframeErrorMessage && <p className="error-message">{iframeErrorMessage}</p>}
      <div ref={tokenExIframeRef} id="tokenExIframeDiv"></div>

      <label className="input-label">Expiration</label>

      <div className="expiration-wrapper">
        <select value={selectedMonth} onChange={e => setSelectedMonth(e.target.value)}>
          {Array.from({ length: 12 }, (_, i) => {
            const monthValue = (i + 1).toString().padStart(2, "0");
            return (
              <option key={monthValue} value={monthValue} data-testid="expiration-month">
                {monthValue}
              </option>
            );
          })}
        </select>

        <select value={selectedYear} onChange={e => setSelectedYear(e.target.value)}>
          {expirationYears.map(year => (
            <option key={year} value={year} data-testid="expiration-year">
              {year}
            </option>
          ))}
        </select>
      </div>

      {errorMessage && (
        <div className="error-message" data-testid="error-message">
          {errorMessage}
        </div>
      )}

      <Button testId="card-submit" isLoading={isLoading} disabled={isLoading} label="Buy Now" onClick={tokenexValidate} />
    </div>
  );
};

export default PaymentForm;
