var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { cloneElement, useContext, useState } from 'react';
import Navbar from './Navbar';
import classNames from 'classnames';
import { ThemeContext } from '../../context/ThemeContext';
var NavbarsInner = function (_a, ref) {
    var children = _a.children, defaultValue = _a.defaultValue, className = _a.className, props = __rest(_a, ["children", "defaultValue", "className"]);
    var navbars = useContext(ThemeContext).theme.navbars;
    var classes = classNames(navbars.content, className);
    var _b = useState(defaultValue), selectedNav = _b[0], setNav = _b[1];
    return (_jsx("div", __assign({ ref: ref, "aria-label": "Navbars", role: "tablist" }, props, { className: classes }, { children: children && children.map(function (child, index) {
            return cloneElement(child, {
                open: index === selectedNav,
                key: index,
                onClickCapture: function () { return setNav(index); },
            });
        }) })));
};
var Navbars = React.forwardRef(NavbarsInner);
export default Object.assign(Navbars, { Navbar: Navbar });
