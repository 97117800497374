import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { InView } from "react-intersection-observer";
import { useRecoilState } from "recoil";

import { customerAtom } from "./atoms/customer";
import { stepAtom } from "./atoms/step";
import Steps from "./components/Steps";
import ServiceCheck from "./steps/Standard/ServiceCheck";
import SelectPlan from "./steps/Standard/SelectPlan";
import ContactInfo from "./steps/Standard/ContactInfo";
import ScheduleAppointment from "./steps/Standard/ScheduleAppointment";
import PaymentInfoIframe from "./steps/Standard/PaymentInfoIframe";
import OrderSummary from "./steps/Standard/OrderSummary";
import OrderConfirmation from "./steps/Standard/OrderConfirmation";

import useTracking from "./hook/useTracking";
import useUpdateCustomerData from "./hook/useUpdateCustomerData";

import "./styles.scss";
import { useFeatureFlag } from "configcat-react";

export const steps = [
  { component: ServiceCheck, name: "Serviceability Check" },
  { component: SelectPlan, name: "Select a Plan" },
  { component: ContactInfo, name: "Contact Information" },
  { component: PaymentInfoIframe, name: "Payment Information" },
  { component: OrderSummary, name: "Order Summary", canGoBack: false },
  {
    component: ScheduleAppointment,
    name: "Appointment Confirmation",
    canGoBack: false,
  },
  {
    component: OrderConfirmation,
    name: "Order Confirmation",
    canGoBack: false,
    hidden: true,
  },
];

const maxSteps = steps.length;

function sendContentHeight() {
  var height = document.body.scrollHeight;
  window.parent.postMessage({ height: height }, "*");
}

export default function StandardCheckout() {
  window.addEventListener("resize", sendContentHeight);

  const [step, setStep] = useRecoilState(stepAtom);
  const [sessionCreated, setSessionCreated] = useState(false);
  const { trackClick } = useTracking();
  const [customer, setCustomer] = useRecoilState(customerAtom);
  const { createSession, createSessionV2, updateCustomerData, updateCustomerDataV2, isLoading } = useUpdateCustomerData();
  const [spots, setSpots] = useState([]);

  const { value: newSessionApi, loading: newSessionApiLoading } = useFeatureFlag("newSessionApi", false);

  const handleinViewChange = (inView, entry) => {
    if (inView && !sessionCreated) {
      const queryParams = new URLSearchParams(window.location.search);
      const queryStrings = {
        partner: queryParams.get("partner"),
        source: queryParams.get("source"),
        medium: queryParams.get("medium"),
        channel: queryParams.get("channel"),
        campaign: queryParams.get("campaign"),
        discountCode: queryParams.get("discountCode"),
        location: queryParams.get("location"),
        referrer: queryParams.get("referrer"),
        term: queryParams.get("term"),
        content: queryParams.get("content"),
        version: window.location.pathname,
      };
      const lms = {};

      for (const key in queryStrings) {
        if (Object.prototype.hasOwnProperty.call(queryStrings, key)) {
          lms[key] = queryStrings[key];
        }
      }
      setCustomer({ ...customer, lms });
      if (!newSessionApiLoading && newSessionApi) {
        createSessionV2({
          step: 0,
          lms,
        });
      }
      createSession({
        step: 0,
        lms,
      });

      // GTM event
      if(process.env.NODE_ENV === "production") {
        window.dataLayer.push({
          event: 'get_started'
        });
      }

      setSessionCreated(true);
    }
  };

  const updateCustomer = async () => {
    await updateCustomerData({ ...customer, step });
    if (!newSessionApiLoading && newSessionApi) {
      await updateCustomerDataV2({ ...customer, step });
    }
  }

  useEffect(() => {
    sendContentHeight();
  }, [step]);

  useEffect(() => {    
    updateCustomer();
  }, [step]);

  useEffect(() => {
    if(customer?.appointment) {
      updateCustomer()
    }    
  }, [customer]);

  const handleStepChange = newStep => {
    if (newStep >= 0 && newStep <= maxSteps) {
      setStep(newStep);
      trackClick(step, newStep);
    }
  };

  const CurrentComponent = steps[step].component;

  return (
    <>
      {!newSessionApiLoading && (
        <InView as="div" onChange={handleinViewChange}>
          <div className="App">
            <div className="m-3">
              {!isMobile && <Steps steps={steps} active={step} className="mt-2 mb-5 d-flex mx-auto" handleStepChange={handleStepChange} />}

              <div className="lg:w-75 mx-auto">
                <CurrentComponent isLoading={isLoading} spots={spots} setSpots={setSpots} />
              </div>
            </div>
          </div>
        </InView>
      )}
    </>
  );
}
