var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, cloneElement, useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ThemeContext } from '../../context/ThemeContext';
var Navbar = function (_a, ref) {
    var children = _a.children, _b = _a.open, open = _b === void 0 ? false : _b, onClick = _a.onClick, disabled = _a.disabled, className = _a.className, title = _a.title, style = _a.style, props = __rest(_a, ["children", "open", "onClick", "disabled", "className", "title", "style"]);
    var navbar = useContext(ThemeContext).theme.navbar;
    var contentStyle = navbar.content;
    var childrenStyle = navbar.children;
    var baseStyle = navbar.base;
    var activeStyle = navbar.active;
    var disabledStyle = navbar.disabled;
    var classes = classNames(contentStyle, className, disabled ? disabledStyle :
        open ? activeStyle : baseStyle);
    var _c = useState(false), navOpened = _c[0], setNavOpened = _c[1];
    var wrapperRef = useRef(null);
    useEffect(function () {
        setNavOpened(open);
        var handleEsc = function (e) {
            if ((e.key === 'Esc' || e.key === 'Escape') && open) {
                setNavOpened(false);
            }
        };
        var handleClickOutside = function (e) {
            if (!open)
                return;
            if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
                setNavOpened(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('keydown', handleEsc);
        return function () {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('keydown', handleEsc);
        };
    }, [open]);
    var handleClick = function () {
        if (!disabled) {
            if (open === true && !navOpened)
                setNavOpened(true);
            onClick && onClick();
        }
    };
    return (_jsx("div", __assign({ ref: wrapperRef }, { children: _jsxs("div", __assign({ ref: ref, role: "navigation" }, props, { className: classes, style: style, onClick: handleClick }, { children: [_jsx("div", { children: title }), _jsx("div", __assign({ className: childrenStyle }, { children: children &&
                        cloneElement(children, __assign(__assign({}, children.props), { open: navOpened })) }))] })) })));
};
var NavbarInner = React.forwardRef(Navbar);
export default NavbarInner;
