var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import AptiveIcon from "../Icon";
import { ThemeContext } from "../../context/ThemeContext";
import Badge from "../Badge";
var Sidebar = React.forwardRef(function (params, ref) {
    var className = params.className, _a = params.isOpen, isOpen = _a === void 0 ? false : _a, _b = params.data, data = _b === void 0 ? [] : _b, onClose = params.onClose, _c = params.selectedList, selectedList = _c === void 0 ? undefined : _c, props = __rest(params, ["className", "isOpen", "data", "onClose", "selectedList"]);
    var sidebar = useContext(ThemeContext).theme.sidebar;
    var _d = useState(undefined), activeSubSidebar = _d[0], setActiveSubSidebar = _d[1];
    var _e = useState(undefined), seletedItem = _e[0], setSeletedItem = _e[1];
    var _f = useState(selectedList), activedItem = _f[0], setActivedItem = _f[1];
    var handleParentSidebarClick = function (sidebarId) {
        setActiveSubSidebar(sidebarId);
        (sidebarId === activeSubSidebar) && handleBackClick();
        setSeletedItem(sidebarId);
        setActivedItem(undefined);
    };
    var handleBackClick = function () {
        var prevData = data.find(function (item) { return item.id === activeSubSidebar; });
        setActiveSubSidebar(prevData.parentId);
    };
    var handleItemClick = function (sidebarId) {
        setSeletedItem(sidebarId);
        setActivedItem(undefined);
    };
    useEffect(function () {
        if (activedItem) {
            var activedItemData = data.find(function (item) { return item.id === activedItem; });
            setActiveSubSidebar(activedItemData === null || activedItemData === void 0 ? void 0 : activedItemData.parentId);
        }
    }, [activedItem, data]);
    var renderSidebarItems = function (parentId, isChild) {
        if (isChild === void 0) { isChild = false; }
        var filteredItems = data.filter(function (item) { return item.parentId === parentId || (!parentId && !item.parentId); });
        var childrenBeforeLastChildrenIds = filteredItems
            .filter(function (item) { return data.some(function (parentItem) { return parentItem.parentId === item.id; }); })
            .sort(function (a, b) { return a.id - b.id; })
            .map(function (item) { return item.id; });
        var itemListLinkCls = classNames(itemListLinkClass, isChild && sidebar.item.childItem);
        return filteredItems.map(function (item) { return ((childrenBeforeLastChildrenIds.includes(item.id))
            ?
                _jsxs("div", { children: [_jsx("li", __assign({ className: classNames(itemListClass, (activedItem === item.id || seletedItem === item.id) && sidebar.item.selected), onClick: function () { return handleParentSidebarClick(item.id); } }, { children: _jsxs("div", __assign({ className: itemTitleClass }, { children: [(activeSubSidebar === item.id) ?
                                        _jsx(AptiveIcon, { className: leftIconClass, icon: "chevronDown", isFilled: true })
                                        :
                                            _jsx(AptiveIcon, { className: leftIconClass, icon: "chevronRight", isFilled: true }), _jsx("span", __assign({ className: sidebar.item.itemTitle }, { children: item.title })), item.badge && _jsx(Badge, __assign({ size: "small", color: item.badgeType }, { children: item.badge }))] })) })), _jsx("li", { children: (activeSubSidebar === item.id) && (_jsx("ul", { children: renderSidebarItems(item.id, true) })) })] }, item.id)
            :
                _jsx("li", __assign({ className: classNames(sidebar.item.base, (activedItem === item.id || seletedItem === item.id) && sidebar.item.selected), onClick: function () { return handleItemClick(item.id); } }, { children: _jsx("a", __assign({ className: itemListLinkCls, href: item.path || '#' }, { children: _jsxs("div", __assign({ className: itemTitleClass }, { children: [_jsx("span", __assign({ className: sidebar.item.itemTitle }, { children: item.title })), item.badge && _jsx(Badge, __assign({ size: "small", color: item.badgeType }, { children: item.badge }))] })) })) }), item.id)); });
    };
    var sidebarClass = classNames(className, sidebar.content, isOpen ? "translate-x-0 " : null);
    var itemListClass = classNames(sidebar.item.base, sidebar.item.list);
    var itemListLinkClass = sidebar.item.link;
    var itemTitleClass = sidebar.item.title;
    var leftIconClass = sidebar.item.leftIcon;
    return (_jsx("ul", __assign({ role: "menu", ref: ref, className: sidebarClass }, props, { onClick: function (e) { return e.stopPropagation(); } }, { children: renderSidebarItems(undefined) })));
});
export default Sidebar;
