import { useState, useRef, useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import { useRecoilState } from "recoil";
import { isMobile } from "react-device-detect";
import { customerAtom } from "../../atoms/customer";
import { stepAtom } from "../../atoms/step";
import Button from "../../components/Button";
import useCheckServiceability from "./../../hook/useCheckServiceability";
import useGetPlans from "./../../hook/useGetPlans";
import { extractAddressLongName } from "../../helpers/placeHelper";

const MESSAGE = "Service is not available in your area";
const INVALID_ADDRESS_MESSAGE = "Please enter a valid address.";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || "AIzaSyCBUicJb-ooCWzodi9ztVYFMwjDT_FKyj8";
Geocode.setApiKey(GOOGLE_API_KEY);

const ServiceCheck = ({ showMessage }) => {
  const { checkServiceability } = useCheckServiceability();
  const { getPlans } = useGetPlans();
  const [customer, setCustomer] = useRecoilState(customerAtom);
  const [step, setStep] = useRecoilState(stepAtom);
  
  const [loading, setLoading] = useState(false);
  const [place, setPlace] = useState();
  const [message, setMessage] = useState(showMessage);

  const [enableBtn, setEnableBtn] = useState(false);
  const [isDoneUpdateExtradata, setIsDoneUpdateExtradata] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current && customer.formatted_address) {
      inputRef.current.value = customer.formatted_address;
    }
  }, [customer.formatted_address]);

  

  const onHandleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();

    if (message || !place) return;

    setLoading(true);
    setEnableBtn(false);  
    try {
      const res = await getPlans(customer.officeId);
      const plans = res.data.plans;
      const products = res.data.products;

      let customerPlans = [];
      plans.forEach((thisPlan) => {
        const areaPlan = thisPlan.default_area_plan;
        let planDetails = {
          id: thisPlan.id,
          name: thisPlan.name,
          initialMax: areaPlan.area_plan_pricings[0].initial_max,
          recurringMax: areaPlan.area_plan_pricings[0].recurring_max,
          addons: areaPlan.addons,
          products: [],          
        };

        products.forEach((thisProduct) => {
          if(areaPlan.service_product_ids.includes(thisProduct.id)) {
            planDetails.products.push(thisProduct);
          }
        });

        customerPlans.push(planDetails);
      });

      

      setCustomer({
        ...customer,
        ...place,
        plans: customerPlans,        
        step: step + 1,        
      });
      
    } catch (e) {
      setEnableBtn(false);      
      setMessage(MESSAGE);
    }    

    setStep(1);
    setEnableBtn(true);  
    setLoading(false);    
  };


  const onGetAddress = async val => {
    const postalCode = extractAddressLongName(val.address_components, "postal_code");

    // Validate the address components array to ensure it has the required fields
    if (!isValidAddressArray(val.address_components)) {
      setMessage(INVALID_ADDRESS_MESSAGE);
      setEnableBtn(false);
      setCustomer({ ...customer, isServiceable: false, place: val, step: 0 });
      return;
    }

    setPlace(val);
    if (!postalCode) {
      setMessage(MESSAGE);
      setEnableBtn(false);
      return;
    }
    setLoading(true);
    try {
      const res = await checkServiceability(postalCode,"");
      const isServiceable = res?.data?.isServiceable;
      const officeId = res?.data?.officeId;

      setMessage(isServiceable ? "" : MESSAGE);
      setEnableBtn(!!isServiceable);
        setCustomer({ ...customer, isServiceable: isServiceable, place: place, officeId: officeId, step: isServiceable ? 1 : 0 });
      
    } catch (e) {
      setEnableBtn(false);
      setCustomer({ ...customer, isServiceable: false, place: val, step: 0 });
      setMessage(MESSAGE);
    }
    setLoading(false);
    setIsDoneUpdateExtradata(false);
  };

  const handleAddressChange = e => {
    Geocode.fromAddress(e.target.value).then(
      response => {
        onGetAddress(response.results[0]);
      },
      error => {
        setMessage(INVALID_ADDRESS_MESSAGE);
        setEnableBtn(false);
      }
    );
  };

  const isValidAddressArray = addressComponents => {
    const requiredTypes = ["street_number", "locality"];
    const typesPresent = addressComponents.map(component => component.types).flat();
    return requiredTypes.every(type => typesPresent.includes(type));
  };

  return (    
      <div className={`p-3 rounded-2 contact-component container`}>
        <form className="py-2" onSubmit={onHandleSubmit}>
          <div className={`contact ${isMobile && 'pt-2'}` }>
            <div className="p-0">
              <div className="form-container p-0 border-0 shadow-none m-0">
                <div className="mb-2">
                  <div className="label-sm">
                    Address<span className="text-danger">*</span>
                  </div>
                  <Autocomplete
                    data-testid="autocomplete"
                    options={{
                      componentRestrictions: { country: "us" },
                      types: ["geocode", "establishment"],
                    }}
                    apiKey={GOOGLE_API_KEY}
                    onPlaceSelected={() => inputRef.current.blur()}
                    onBlur={handleAddressChange}
                    ref={inputRef}
                    language="en"
                    className="form-control mb-1"
                    placeholder=""
                  />
                </div>                                

                {message && <h4 className="text-danger">{message}</h4>}
              </div>
            </div>
            <div className="contact-btns m-3">
              <div className={`${isMobile && 'contact-btns-right'}`}>
                <Button
                  testId="address-submit"
                  isLoading={loading || isDoneUpdateExtradata}
                  disabled={!enableBtn || isDoneUpdateExtradata}
                  label="View Plans"
                  type="submit"
                />
              </div>
            </div>        
          </div>
        </form>        
      </div>
  );
};

export default ServiceCheck;
