export var AvatarImgs = {
    placeholder: {
        "label": "Placeholder",
        "imgSrc": "/assets/image/avatar/avatar-placeholder.svg"
    },
    user: {
        "label": "User",
        "imgSrc": "/assets/image/avatar/user.png"
    }
};
