import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { stepAtom } from "../../atoms/step";

import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import CustomerSummaryInfo from "../../components/CustomerSummaryInfo";
import YouTube from "react-youtube";
import ContactSupport from "../../components/ContactSupport";

export default function OrderConfirmation() {
  const step = useRecoilValue(stepAtom);

  useEffect(() => {
    // GTM event
    if(process.env.NODE_ENV === "production") {
      window.dataLayer.push({
        event: 'service_contact_info'
      });
    }
  }, []);

  return (
    <div className="step step-7">
      <Title>Thank you for your order.</Title>
      <SubTitle dataTestId="sub-title">Your appointment has been successfully scheduled!</SubTitle>

      <div className="container">
        <CustomerSummaryInfo step={step} />

        <div className="youtube">
          <div className="youtube-title">
            Learn more about your new Aptive pest plan.{" "}
            <a href="https://www.aptive.us/pro" target="_blank" rel="noreferrer">
              Check out our welcome brochure!
            </a>
          </div>
          <YouTube videoId="LOOmxxeM-ns" />
        </div>
      </div>
      <ContactSupport message="Need additional help? Reach out to our support team at 1-844-422-6619." />
    </div>
  );
}
