var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { ThemeContext } from '../../context/ThemeContext';
import FocusLock from 'react-focus-lock';
var Dropdown = React.forwardRef(function Dropdown(props, ref) {
    var children = props.children, onClose = props.onClose, _a = props.isOpen, isOpen = _a === void 0 ? false : _a, className = props.className, other = __rest(props, ["children", "onClose", "isOpen", "className"]);
    var dropdown = useContext(ThemeContext).theme.dropdown;
    var baseStyle = dropdown.base;
    var handleEsc = useCallback(function (e) {
        if (e.key === 'Esc' || e.key === 'Escape') {
            onClose();
        }
    }, [onClose]);
    var dropdownRef = useRef(null);
    var handleClickOutside = useCallback(function (e) {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            onClose();
        }
    }, [onClose]);
    useEffect(function () {
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('keydown', handleEsc);
        return function () {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('keydown', handleEsc);
        };
    }, [handleClickOutside, handleEsc]);
    var cls = classNames(baseStyle, className);
    var transitionStyle = {
        enter: dropdown.transition.enter,
        enterFrom: dropdown.transition.enterFrom,
        enterTo: dropdown.transition.enterTo,
        leave: dropdown.transition.leave,
        leaveFrom: dropdown.transition.leaveFrom,
        leaveTo: dropdown.transition.leaveTo
    };
    return (_jsx(Transition, __assign({ show: isOpen, enter: transitionStyle.enter, enterFrom: transitionStyle.enterFrom, enterTo: transitionStyle.enterTo, leave: transitionStyle.leave, leaveFrom: transitionStyle.leaveFrom, leaveTo: transitionStyle.leaveTo }, { children: _jsx(FocusLock, __assign({ returnFocus: true }, { children: _jsx("div", __assign({ ref: ref }, { children: _jsx("div", __assign({ className: cls, ref: dropdownRef, "aria-label": "submenu" }, other, { children: children })) })) })) })));
});
export default Dropdown;
