import { useState } from "react";
import { apiRequest } from "../api/apiRequest";
import {
  createCustomerDataNewSessionUrl,
  createCustomerDataSessionUrl,
  getUpdateCustomerDataUrl,
  getUpdateCustomerNewDataUrl,
} from "../api/apiEndpoints";

const useUpdateCustomerData = () => {
  const [token, setToken] = useState();
  const [tokenNew, setTokenNew] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const updateCustomerData = async data => {
    if (token) {
      setIsLoading(true);
      return apiRequest({
        url: getUpdateCustomerDataUrl(),
        method: "post",
        data,
        configs: {
          withCredentials: true,
          headers: {
            "X-Token": token,
          },
        },
      }).finally(() => setIsLoading(false));
    }
  };

  const updateCustomerDataV2 = async data => {
    if (tokenNew) {
      setIsLoading(true);
      return apiRequest({
        url: getUpdateCustomerNewDataUrl(),
        method: "patch",
        data,
        configs: {
          withCredentials: true,
          headers: {
            "X-Token": tokenNew,
          },
        },
      }).finally(() => setIsLoading(false));
    }
  };
  const createSession = async data => {
    setIsLoading(true);
    return apiRequest({
      url: createCustomerDataSessionUrl(),
      method: "post",
      data,
      configs: {
        withCredentials: true,
      },
    })
      .then(data => {
        setToken(data?.data?.token);
      })
      .finally(() => setIsLoading(false));
  };

  const createSessionV2 = async data => {
    setIsLoading(true);
    return apiRequest({
      url: createCustomerDataNewSessionUrl(),
      method: "post",
      data,
      configs: {
        withCredentials: true,
      },
    })
      .then(data => {
        setTokenNew(data?.data?.token);
      })
      .finally(() => setIsLoading(false));
  };

  return { createSession, updateCustomerData, updateCustomerDataV2, createSessionV2, isLoading };
};

export default useUpdateCustomerData;
