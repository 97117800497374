import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

export function formatDateNotYear(dateString, type) {
  if (type === "m-d") return format(parseISO(dateString), "MMMM d");
  if (type === "m-do") return format(parseISO(dateString), "MMMM do");
  if (type === "d") return format(parseISO(dateString), "d");
  if (type === "endDate") {
    const startDate = new Date(dateString);
    startDate.setDate(startDate.getDate() + 7);
    const options = { month: "long", day: "numeric" };
    var endDate = startDate.toLocaleDateString("en-US", options);
    return endDate;
  }
}
