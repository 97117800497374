import React from "react";

const MaintenanceMode = () => {
  return (
    <div className="maintenance-mode">
      <div className="maintenance-wrapper">
        <h3>Temporarily Unavailable.</h3>
        <p>
          {`We're sorry, but our system is currently unavailable due to maintenance.`}
        </p>

        <p>
          {`We apologize for any inconvenience this may cause and thank you for your patience.`}
        </p>
      </div>
    </div>
  );
};

export default MaintenanceMode;
