import axios from "axios";

export const apiRequest = async ({ url, method = "get", data, configs }) => {
  return axios({
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      ...configs?.headers,
    },
    url,
    method,
    data,
    ...configs,
  });
};
