import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { InView } from "react-intersection-observer";
import { Accordion } from "react-bootstrap";

import { customerAtom } from "./atoms/customer";
import { stepAtom } from "./atoms/step";

import PlanPayment from "./steps/Express/PlanPayment";
import ExpressScheduleAppointment from "./steps/Express/ExpressScheduleAppointment";
import ExpressOrderConfirmation from "./steps/Express/ExpressOrderConfirmation";
import UserInfo from "./steps/Express/UserInfo";
import useUpdateCustomerData from "./hook/useUpdateCustomerData";
import { useFeatureFlag } from "configcat-react";

const ExpressCheckout = () => {
  const [step, setStep] = useRecoilState(stepAtom);
  const [showScheduleAppointment, setShowScheduleAppointment] = useState(false);
  const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
  const [sessionCreated, setSessionCreated] = useState(false);
  const customer = useRecoilValue(customerAtom);
  const { createSession, updateCustomerData, createSessionV2, updateCustomerDataV2, isLoading } = useUpdateCustomerData();

  const { value: newSessionApi, loading: newSessionApiLoading } = useFeatureFlag("newSessionApi", false);

  const handleCustomerUpdate = async newCustomerData => {
    await updateCustomerData(newCustomerData);
    if (!newSessionApiLoading && newSessionApi) {
      await updateCustomerDataV2(newCustomerData);
    }
  };

  const updateSchedule = flag => {
    setShowScheduleAppointment(flag);
  };

  const updateOrder = flag => {
    setShowOrderConfirmation(flag);
  };

  const renderIcon = currentStep => {
    if (currentStep === step) {
      return (
        <span className="fa-stack">
          <i className="fa fa-circle fa-stack-1x" style={{ color: "#e1ff32", fontSize: "50px" }} />
          <i className="fa fa-dot-circle-o fa-stack-1x" style={{ fontSize: "50px" }} />
        </span>
      );
    } else if (step < currentStep) {
      return (
        <span className="fa-stack">
          <i className="fa fa-circle-o fa-stack-1x" style={{ fontSize: "50px", color: "#e4e7eb" }} />
        </span>
      );
      
    } else {
      return (
        <span className="fa-stack text-success">
          <i className="fa fa-check-circle-o fa-stack-1x" style={{ fontSize: "50px" }} />
        </span>
      );
    }
  };

  const handleSelect = eventKey => {
    const newStep = parseInt(eventKey || 0, 10);
    if (newStep === 0 && step === 0) {
      return;
    }
    if (newStep > 0 && newStep < step) {
      return;
    }
    if (newStep === step || newStep === step - 1) {
      setStep(newStep);
    }
  };

  const handleinViewChange = (inView, entry) => {
    if (!sessionCreated) {
      const queryParams = new URLSearchParams(window.location.search);
      const queryStrings = {
        partner: queryParams.get("partner"),
        source: queryParams.get("source"),
        medium: queryParams.get("medium"),
        channel: queryParams.get("channel"),
        campaign: queryParams.get("campaign"),
        discountCode: queryParams.get("discountCode"),
        location: queryParams.get("location"),
        referrer: queryParams.get("referrer"),
        term: queryParams.get("term"),
        content: queryParams.get("content"),
        version: window.location.pathname,
      };
      const lms = {};
      for (const key in queryStrings) {
        if (Object.prototype.hasOwnProperty.call(queryStrings, key)) {
          lms[key] = queryStrings[key];
        }
      }

      // GTM event
      if(process.env.NODE_ENV === "production") {
        window.dataLayer.push({
          event: 'get_started'
        });
      }

      const initialCustomerData = { ...customer, lms, step: 0 };
      createSession(initialCustomerData).then(() => {
        setSessionCreated(true);
      });

      if (!newSessionApiLoading && newSessionApi) {
        createSessionV2(initialCustomerData).then(() => {
          setSessionCreated(true);
        });
      }
    }
  };

  useEffect(() => {
    if (sessionCreated) {
      handleCustomerUpdate(customer);
    }
  }, [step]);

  return (
    <>
      {!newSessionApiLoading && (
        <InView as="div" onChange={handleinViewChange}>
          <div className="m-1">
            <Accordion className={"express-accordion"} defaultActiveKey="0" activeKey={`${step}`} onSelect={handleSelect}>
              <Accordion.Item eventKey="0">
                <Accordion.Header data-testid="accordion-step1">
                  <div className={`express-step-item fs-3 d-flex align-items-center ${step > 0 && "text-success fw-bold "}`}>
                    {renderIcon(0)}
                    <div className="express-step-data">
                      <div className="express-step-number mb-0">Step 1</div>
                      <div className="express-step-title">Customer Information</div>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <UserInfo isLoading={isLoading} />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" disabled={step < 1}>
                <Accordion.Header data-testid="accordion-step2">
                  <div className={`express-step-item fs-3 d-flex align-items-center ${step > 1 && "text-success fw-bold "}`}>
                    {renderIcon(1)}
                    <div className="express-step-data">
                      <div className="express-step-number">Step 2</div>
                      <div className="express-step-title">Purchase Plan</div>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <PlanPayment updateSchedule={updateSchedule} isLoading={isLoading} />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" disabled={step < 2}>
                <Accordion.Header>
                  <div className={`express-step-item fs-3 d-flex align-items-center ${step > 2 && "text-success fw-bold "}`}>
                    {renderIcon(2)}
                    <div className="express-step-data">
                      <div className="express-step-number mb-0">Step 3</div>
                      <div className="express-step-title">Confirmation</div>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {showScheduleAppointment && (
                    <ExpressScheduleAppointment updateSchedule={updateSchedule} isLoading={isLoading} updateOrder={updateOrder} />
                  )}
                  {showOrderConfirmation && <ExpressOrderConfirmation updateSchedule={updateSchedule} isLoading={isLoading} />}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </InView>
      )}
    </>
  );
};

export default ExpressCheckout;
