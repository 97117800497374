import { useFeatureFlag } from "configcat-react";
import React, { useState, useRef, useEffect } from "react";
import { useRecoilState } from "recoil";
import { customerAtom } from "../../atoms/customer";
import { stepAtom } from "../../atoms/step";

import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";

import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import Button from "../../components/Button";

import useCheckServiceability from "../../hook/useCheckServiceability";
import { extractAddressShortName, extractAddressLongName } from "../../helpers/placeHelper";

const MESSAGE = "Service is not available in your area";
const INVALID_ADDRESS_MESSAGE = "Please enter a valid address.";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || "AIzaSyCBUicJb-ooCWzodi9ztVYFMwjDT_FKyj8";
Geocode.setApiKey(GOOGLE_API_KEY);

const getAddressInfo = address => ({
  address: extractAddressShortName(address, "street_number") + " " + extractAddressShortName(address, "route"),
  city: extractAddressLongName(address, "locality"),
  state: extractAddressShortName(address, "administrative_area_level_1"),
  county: extractAddressLongName(address, "administrative_area_level_2"),
  country: extractAddressShortName(address, "country"),
  postal_code: extractAddressLongName(address, "postal_code"),
});

export default function ServiceCheck({ isLoading, showMessage }) {
  const [step, setStep] = useRecoilState(stepAtom);
  const [customer, setCustomer] = useRecoilState(customerAtom);
  const discountRef = useRef();
  const [place, setPlace] = useState();
  const [message, setMessage] = useState(showMessage);
  const [extraData, setExtraData] = useState();
  const [enableBtn, setEnableBtn] = useState(false);
  const { checkServiceability } = useCheckServiceability();
  const [loading, setLoading] = useState(false);
  const [discountCode, setDiscountCode] = useState(customer?.discount_code);

  const [localPostCode, setLocalPostCode] = useState("");
  const [isDoneUpdateExtradata, setIsDoneUpdateExtradata] = useState(false);
  const inputRef = useRef(null);
  const { value: isCouponCodeEnabled } = useFeatureFlag("isCouponCodeEnabled", false);

  useEffect(() => {
    setDiscountCode(customer?.lms?.discountCode || "");
  }, [customer]);

  useEffect(() => {
    if(step === 0 && customer.address_components) {
      onGetAddress(customer);
    }
  }, [step]);

  useEffect(() => {
    if (inputRef.current && customer.formatted_address) {
      inputRef.current.value = customer.formatted_address;
    }
  }, [customer.formatted_address]);
  const onHandleChangeDiscountCode = e => {
    setDiscountCode(e.target.value);
    clearTimeout(discountRef.current);
    discountRef.current = setTimeout(async () => {
      setIsDoneUpdateExtradata(true);
      try {
        const res = await checkServiceability(localPostCode, e.target.value);

        setExtraData({
          ...extraData,
          prices: res?.data?.prices,
        });
        setLocalPostCode(localPostCode);
        setMessage(res?.data?.isServiceable ? "" : MESSAGE);
        setEnableBtn(!!res?.data?.isServiceable);
      } catch (e) {
        setEnableBtn(false);
        setMessage(MESSAGE);
      }
      setIsDoneUpdateExtradata(false);
    }, 300);
  };

  const onGetAddress = async val => {
    const postalCode = extractAddressLongName(val.address_components, "postal_code");

    // Validate the address components array to ensure it has the required fields
    if (!isValidAddressArray(val.address_components)) {
      setMessage(INVALID_ADDRESS_MESSAGE);
      setEnableBtn(false);
      setCustomer({ ...customer, isServiceable: false, place: val, step: 0 });
      return;
    }

    setPlace(val);
    if (!postalCode) {
      setMessage(MESSAGE);
      setEnableBtn(false);
      return;
    }
    setLoading(true);
    const addressInfo = getAddressInfo(val.address_components);
    try {
      const res = await checkServiceability(postalCode, discountCode);
      setLocalPostCode(postalCode);
      const newExtraData = {
        officeId: res?.data?.officeId,
        prices: res?.data?.prices,
        addons: res?.data?.addons,
        frequency: res?.data?.frequency,
        ...addressInfo,
      };
      setExtraData(newExtraData);
      const isServiceable = res?.data?.isServiceable;
      setMessage(isServiceable ? "" : MESSAGE);
      setEnableBtn(!!isServiceable);
      if (!isServiceable) {
        setCustomer({ ...customer, isServiceable: false, place: val, extraData: newExtraData, step: 0 });
      }
    } catch (e) {
      setEnableBtn(false);
      setCustomer({ ...customer, isServiceable: false, place: val, step: 0 });
      setMessage(MESSAGE);
    }
    setLoading(false);
  };

  const handleClickNextButton = async () => {
    if (message || !place) return;

    const newData = {
      ...customer,
      ...place,
      ...extraData,
      discount_code: discountCode,
      isServiceable: true,
    };
    setCustomer(newData);
    setStep(step + 1);
  };

  const handleAddressChange = e => {
    Geocode.fromAddress(e.target.value).then(
      response => {
        onGetAddress(response.results[0]);
      },
      error => {
        setMessage(INVALID_ADDRESS_MESSAGE);
        setEnableBtn(false);
      }
    );
  };

  const isValidAddressArray = addressComponents => {
    const requiredTypes = ["street_number", "locality"];
    const typesPresent = addressComponents.map(component => component.types).flat();
    return requiredTypes.every(type => typesPresent.includes(type));
  };

  return (
    <div className="container-step-1">
      <Title>
        Get a tailored pest plan with <span className="unlimited">unlimited</span> support.
      </Title>
      <SubTitle>To get started, please enter the address of the property to be treated.</SubTitle>
      <div className="form-container">
        <div>
          <div className="address-title">Address</div>
          <Autocomplete
            data-testid="autocomplete"
            options={{
              componentRestrictions: { country: "us" },
              types: ["geocode", "establishment"],
            }}
            apiKey={GOOGLE_API_KEY}
            onPlaceSelected={() => inputRef.current.blur()}
            onBlur={handleAddressChange}
            ref={inputRef}
            language="en"
            className="form-control text-center mb-2"
          />
        </div>
        {isCouponCodeEnabled && (
          <div className="discount">
            <div className="address-title-discount">Discount Code</div>
            <input
              value={discountCode}
              onChange={onHandleChangeDiscountCode}
              placeholder="Enter a discount code"
              className="form-control text-center mb-3"
              type="text"
              data-testid="discount-code"
            />
          </div>
        )}

        {message && <h4 className="text-danger">{message}</h4>}
        <div className="description">
          Why do we need your information? Your address allows us to determine if you are in an area we service.
        </div>

        <Button
          testId="select-a-plan"
          isLoading={loading || isLoading || isDoneUpdateExtradata}
          disabled={!enableBtn || isLoading || isDoneUpdateExtradata}
          label="Next: Select a Plan"
          onClick={handleClickNextButton}
        />
      </div>
    </div>
  );
}
