import { useRecoilState } from "recoil";

import { customerAtom } from "../../../atoms/customer";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import useGetAvailableSpots from "../../../hook/useGetAvailableSpots";
import useCreateAppointment from "../../../hook/useCreateAppointment";
import ContactSupport from "../../../components/ContactSupport";
import ExpressScheduleAppointmentUI from "./ExpressScheduleAppointment";
import { useFeatureFlag } from "configcat-react";

export default function ExpressScheduleAppointment({ updateSchedule, updateOrder, activeStep }) {
  const [customer, setCustomer] = useRecoilState(customerAtom);

  const onSuccess = spots => {
    setCustomer({
      ...customer,
      spots,
      step: activeStep + 1,
    });
  };
  const {
    availableSpots,
    loading: isLoadingSpots,
    extractSpotIds,
    flattenSpots,
  } = useGetAvailableSpots(customer.customerId, customer.accessToken, customer?.geometry?.location, onSuccess, [], true);

  const { createAppointment, updateAppointment } = useCreateAppointment(customer.accessToken);

  const { value: autoBookSpot, loading: autoBookSpotLoading } = useFeatureFlag("autoBookSpot", false);

  const [selectedSpot, setSelectedSpot] = useState(null);
  const [unavailableSpots, setUnavailableSpots] = useState([]);
  const [availableSelectedSpot, setAvailableSelectedSpot] = useState([]);
  const [selectingDefault, setSelectingDefault] = useState(autoBookSpot);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSelectSpot = spot => {
    setSelectedSpot(spot);
    setAvailableSelectedSpot(extractSpotIds(spot.date, spot.window));
  };

  const getPayload = useCallback(
    (planNumber, spot) => ({
      subscriptionId: planNumber,
      spotId: spot?.spotID,
      isAroSpot: spot?.isAroSpot,
      window: spot?.window,
    }),
    []
  );

  const submitAppointment = useMemo(
    () => (customer?.appointment ? updateAppointment : createAppointment),
    [customer?.appointment, updateAppointment, createAppointment]
  );

  const handleConfirmAppointment = useCallback(
    async (defaultSpot = undefined) => {
      if (!defaultSpot && customer.appointment?.window === selectedSpot?.window && customer.appointment?.date === selectedSpot?.date) {
        updateSchedule(false);
        updateOrder(true);
        return;
      }
      try {
        if (!defaultSpot) setLoading(true);
        setErrorMessage("");
        const pollingInterval = 200;
        let response;
        const spotsToSubmit = defaultSpot ? extractSpotIds(defaultSpot.date, defaultSpot.window) : availableSelectedSpot;
        for (const spot of spotsToSubmit.reverse()) {
          response = await submitAppointment(customer.customerId, getPayload(customer.subscriptionId, spot));

          if (response.status === 201) {
            // GTM event
            if(process.env.NODE_ENV === "production") {
              window.dataLayer.push({
                event: 'appointment_confirmation'
              });
            }

            const { appointment } = response?.data || {};
            setCustomer({
              ...customer,
              appointment,
              step: activeStep + 1,
              selectedSpot: spot,
            });
            
            if (!defaultSpot) {
              updateSchedule(false);
              updateOrder(true);
            } 
                       
            break;
          }

          await new Promise(resolve => setTimeout(resolve, pollingInterval));
        }
        if (!response || response.status !== 201) {
          if (!defaultSpot) {
            setErrorMessage("The appointment you selected is no longer available. Please choose another time");
            setSelectedSpot(null);
          }
          setUnavailableSpots([...unavailableSpots, selectedSpot]);
        }
      } catch (error) {
        if (!defaultSpot) {
          setErrorMessage("The appointment you selected is no longer available. Please choose another time");
          setSelectedSpot(null);
        }
        setUnavailableSpots([...unavailableSpots, selectedSpot]);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [
      customer,
      selectedSpot,
      getPayload,
      setLoading,
      availableSelectedSpot,
      unavailableSpots,
      setUnavailableSpots,
      setCustomer,
      extractSpotIds,
      submitAppointment,
    ]
  );

  const autoSelectFirstSpot = useCallback(
    (index = 0) => {
      setSelectingDefault(true);
      if (flattenSpots[index]) {
        handleConfirmAppointment(flattenSpots[index])
          .then(() => {
            setSelectingDefault(false);
            handleSelectSpot(flattenSpots[index]);
          })
          .catch(() => autoSelectFirstSpot(index + 1));
      } else {
        setSelectingDefault(false);
        setErrorMessage(
          "We are having difficulty displaying available appointments. Please call 1-844-422-6619 to get an appointment scheduled."
        );
      }
    },
    [handleConfirmAppointment, flattenSpots]
  );

  useEffect(() => {
    if (!autoBookSpotLoading && flattenSpots?.length && autoBookSpot) {
      autoSelectFirstSpot();
    }
  }, [flattenSpots, autoBookSpot, autoBookSpotLoading]);

  return (
    <>
      <ExpressScheduleAppointmentUI
        customer={customer}
        isLoadingSpots={isLoadingSpots}
        autoBookSpot={autoBookSpot}
        isSelectingDefaultSpot={selectingDefault}
        availableSpots={availableSpots}
        unavailableSpots={unavailableSpots}
        handleSelectSpot={handleSelectSpot}
        selectedSpot={selectedSpot}
        handleConfirmAppointment={handleConfirmAppointment}
        loading={loading}
        errorMessage={errorMessage}
        updateSchedule={updateSchedule}
        updateOrder={updateOrder}
      />
      <ContactSupport message="Need additional help? Reach out to our support team at 1-844-422-6619." />
    </>
  );
}
