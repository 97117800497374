import React from "react";

const Button = ({
  testId,
  label,
  onClick,
  className,
  disabled,
  isLoading,
  icon = <i className="fa fa-angle-right" />,
  type = "button",
  children,
}) => {
  return (
    <button
      data-testid={testId}
      type={type}
      onClick={onClick}
      className={`button ${isLoading ? "loading" : ""} ${className || ""}`}
      disabled={disabled || isLoading}
    >
      {children}
      {label && <span className="fs-lg fs-sm">{label}</span>}
      {isLoading ? <i className="fa fa-circle-o-notch fa-spin fa-fw" /> : icon}
    </button>
  );
};

export default Button;
