import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";

import { customerAtom } from "../../atoms/customer";
import { stepAtom } from "../../atoms/step";

import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import ContactSupport from "../../components/ContactSupport";
import useSignup from "../../hook/useSignup";
import PaymentForm from "../../components/PaymentForm";

import { useFeatureFlag } from "configcat-react";
import CustomerSummaryInfo from "../../components/CustomerSummaryInfo";
import useGetAvailableSpots from "../../hook/useGetAvailableSpots";

window.worldpayCallback = function () {
  return;
};

export default function ExpressPayment({ iframeURL, updateSchedule }) {
  const { value: useLegacyPaymentProvider, loading: useLegacyPaymentProviderLoading } = useFeatureFlag("useLegacyPaymentProvider", true);
  const { signup } = useSignup();
  const [signingUp, setSigningUp] = useState(false);

  const [step, setStep] = useRecoilState(stepAtom);  
  const [customer, setCustomer] = useRecoilState(customerAtom);
  const { getData } = useGetAvailableSpots(customer.customerId, customer.accessToken, customer?.geometry?.location);
  
  const [paymentSubmitted, setPaymentSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("An error has occured");  

  const createSignupPayload = (customerData) => {
    const cleanPhoneNumber = customerData.phone.replace(/\D/g, "").slice(-10);
    const payload = {
      customer: {
        firstName: customerData.firstName,
        lastName: customerData.lastName,
        address: customerData.address,
        city: customerData.city,
        county: customerData.county,
        state: customerData.state,
        postalCode: customerData.postal_code,
        phone: cleanPhoneNumber,
        email: customerData.email,        
        latitude: customerData.geometry.location.lat,
        longitude: customerData.geometry.location.lng
      },
      subscription: {
        serviceId: customerData.planNumber,
      },
      payment_data: {
        ...(customerData.payment_data ? customerData.payment_data : {}),
      },
      paymentProfile: {
        token: customerData.worldpay?.PaymentAccountID,
      },
      use_promotional: customerData.prices?.promoPrices ? true : false,
      use_aptive_payment_service: customerData.use_aptive_payment_service,
    };

    if (customerData.discount_code) {
      payload.discount_code = customerData.discount_code;
    }

    return payload;
  }

  const onHandlePlaceOrder = async (newCustomer) => {        
    const payload = createSignupPayload(newCustomer);

    try {
      setSigningUp(true);
      const res = await signup(payload);
      const newData = {
        ...newCustomer,
        ...res?.data,
      };
      setCustomer(newData);      

      setSigningUp(false);      
      updateSchedule(true);
      setStep(2);

      const callback = spots => {
        setCustomer({
          ...newData,
          step: step + 1,
          spots,
          ...(!spots?.length && { getAvailableSpotsMessage: "Spots were not received" }),
        });        
        setErrorMessage("");
      };
      await getData({ signupRes: res?.data, callback });
    } catch (error) {      
      setSigningUp(false);
      setError(true);
      updateSchedule(false);

      if (error.response.data.isDuplicate) {
        setErrorMessage("This customer account already exists.");
      } else {
        setErrorMessage("An error occured during the signup process.");
      }
    }    
  };

  useEffect(() => {
    window.worldpayCallback = function (data) {
      // GTM event
      if(process.env.NODE_ENV === "production") {
        window.dataLayer.push({
          event: 'payment_info'
        });
      }
      
      const newCustomer = {
        ...customer,
        worldpay: data,
        step: step + 1,
      }
      setPaymentSubmitted(true);
      onHandlePlaceOrder(newCustomer);
    };
  }, [customer]);

  // Tokenex Only
  const onSubmit = data => {
    // GTM event
    if(process.env.NODE_ENV === "production") {
      window.dataLayer.push({
        event: 'payment_info'
      });
    }
    
    const newCustomer = {
      ...customer,
      payment_data: data,
      worldpay: data,
      step: step + 1,
      use_aptive_payment_service: true,
    };

    setPaymentSubmitted(true);
    onHandlePlaceOrder(newCustomer);
  };

  return (
    <div className="step step-4">
      <Title>
        <span className="title-sm">Payment Information</span>
      </Title>
      <SubTitle>
        <span className="fs-sm">You will not be charged today. Payment will be due following each treatment.</span>
      </SubTitle>

      <div className="container">
        <div className="summary">{customer && <CustomerSummaryInfo />}</div>

        {!useLegacyPaymentProviderLoading &&
          (useLegacyPaymentProvider ? (
            <>
              {!paymentSubmitted &&
                (iframeURL ? (
                  <iframe id="payframe" src={iframeURL} title="Payment Iframe" width="100%" height="280px" />
                ) : (
                  <div style={{ height: "280px" }}></div>
                ))
              }
            </>
          ) : (
            !paymentSubmitted && <PaymentForm onSubmit={onSubmit} />
        ))}

        {signingUp && 
          <div>                  
            <div className="express-signup-wait">
              <div>Please wait</div>
              <i className="fa fa-circle-o-notch fa-spin fa-fw" />
            </div>                  
          </div>
        }

        {error && (
          <>                  
            <div className="place-order-error-text">
              <div>{errorMessage}</div>
              <div>Please contact our support team at 1-855-642-8632 for more help.</div>
            </div>
          </>
        )}        
      </div>
      <ContactSupport />
    </div>
  );
}
