var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import classNames from "classnames";
import { FilledIcons } from "../../shared/Icon";
import AptiveIcon from "../Icon";
import { useControlledState } from '@react-stately/utils';
import Button from "../Button/Button";
export var Alert = function (_a) {
    var id = _a.id, className = _a.className, children = _a.children, _b = _a.status, status = _b === void 0 ? 'info' : _b, dismissible = _a.dismissible, onDismiss = _a.onDismiss, _c = _a.dismissed, dismissed = _c === void 0 ? false : _c, isIcon = _a.isIcon, header = _a.header, isViewMore = _a.isViewMore, onClickViewMore = _a.onClickViewMore, other = __rest(_a, ["id", "className", "children", "status", "dismissible", "onDismiss", "dismissed", "isIcon", "header", "isViewMore", "onClickViewMore"]);
    var alert = useContext(ThemeContext).theme.alert;
    var _d = useControlledState(dismissed, false, function (val) {
        return val && onDismiss ? onDismiss() : null;
    }), dismissedState = _d[0], setDismissedState = _d[1];
    var baseStyle = alert.base;
    var statusStyle = alert[status];
    var iconBaseStyle = alert.icon.base;
    var iconTypeStyle = alert.icon[status];
    var Icon;
    switch (status) {
        case 'success':
            Icon = FilledIcons["checkCircle"];
            break;
        case 'warning':
            Icon = FilledIcons["informationCircle"];
            break;
        case 'danger':
            Icon = FilledIcons["xCircle"];
            break;
        case 'info':
            Icon = FilledIcons["informationCircle"];
            break;
        case 'dark':
            Icon = FilledIcons["informationCircle"];
            break;
        default: Icon = FilledIcons["informationCircle"];
    }
    var cls = classNames(baseStyle, statusStyle, className);
    var iconCls = classNames(iconBaseStyle, iconTypeStyle, 'p-[1px] mr-[10px]');
    var closeCls = classNames(iconBaseStyle, iconTypeStyle, 'ml-[12px]');
    var withHeaderStyle = alert.withHeaderStyle;
    var noHeaderStyle = alert.noHeaderStyle;
    var closeBtnStyle = alert.closeBtnStyle;
    var childrenBodyStyle = alert.childrenBodyStyle;
    var actionButtonStyle = alert.actionButtonStyle;
    if (dismissedState) {
        return null;
    }
    return (_jsxs("div", __assign({ id: id, className: cls, role: "alert" }, other, { children: [_jsxs("div", __assign({ className: "flex items-center" }, { children: [isIcon && _jsx(Icon, { className: iconCls }), header ? _jsx("div", __assign({ className: withHeaderStyle, "aria-label": "Header" }, { children: header })) : _jsx("div", __assign({ className: noHeaderStyle }, { children: children })), dismissible && (_jsx("button", __assign({ onClick: function () { return setDismissedState(true); }, className: closeBtnStyle, "aria-label": "Close" }, { children: _jsx(AptiveIcon, { className: closeCls, icon: "x", isFilled: true }) })))] })), header &&
                (_jsx("div", __assign({ className: childrenBodyStyle }, { children: _jsx("div", { children: children }) }))), isViewMore && _jsx("div", __assign({ className: actionButtonStyle }, { children: _jsx(Button, __assign({ icon: "eye", isFilled: true, size: "small", "aria-label": "View More", type: "button", variant: status, onClick: onClickViewMore }, { children: "View more" })) }))] })));
};
