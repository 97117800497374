import DaySchedule from "./DaySchedule";
import some from "lodash/some";

export default function WeekSchedule({
  week,
  isThisWeek,
  onSelectSpot,
  selectedSpot,
  unavailableSpots,
  testid,
}) {
  const checkAvailability = (date, window) => {
    return some(
      unavailableSpots,
      (spot) => spot?.date === date && spot?.window === window
    )
      ? null
      : week[date][window];
  };

  return (
    <div data-testid={testid} className="week-container">
      <div className="week-time">
        <div className="week-time-label">
          <span className="week-time-label-text">
            {isThisWeek ? "THIS WEEK" : "NEXT WEEK"}
          </span>
        </div>
      </div>

      <div>
        {Object.keys(week).map((date) => (
          <DaySchedule
            key={date}
            date={date}
            amSpot={checkAvailability(date, "AM")}
            pmSpot={checkAvailability(date, "PM")}
            selectedSpot={selectedSpot}
            onSelectSpot={onSelectSpot}
          />
        ))}
      </div>
    </div>
  );
}
