export const HOST_NAME = process.env.HOST_NAME || window.location.origin;

export const API_URL = process.env.REACT_APP_API_URL || "https://api.self-service.tst.goaptive.com";

export const LOGGING_URL = process.env.REACT_APP_LOGGING_URL || "https://sco.beta.goaptivetech.com";

export const TRACKING_URL = process.env.REACT_APP_TRACKING_URL || "https://beta.metrics.goaptivetech.com/a/logger/cxp";

export const DATA_DOG_ENV = process.env.REACT_APP_DATA_DOG_ENV;

export const GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

export const RECORD_URL = process.env.REACT_APP_RECORD_URL || "https://api.lead-manager.stg.goaptive.com/api/session";
export const TRACKING_TOKEN = process.env.REACT_APP_TRACKING_TOKEN || "b73248addb9e0b2f9c6b5219c565872f6c1d884a9b3c277a77beb6fba1c6b3cc";
export const PRO_SERVICE_ID = process.env.REACT_APP_PRO_SERVICE_ID || 1800;
export const PREMIUM_SERVICE_ID = process.env.REACT_APP_PREMIUM_SERVICE_ID || 2828;
