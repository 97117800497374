import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useRecoilValue } from "recoil";

import { stepAtom } from "./atoms/step";
import { customerAtom } from "./atoms/customer";
import Title from "./components/Title";
import SubTitle from "./components/SubTitle";

import useUpdateCustomerData from "./hook/useUpdateCustomerData";

import "./styles.scss";
import ServiceCheck from "./steps/CustomerSupport/ServiceCheck";
import ViewPlans from "./steps/CustomerSupport/ViewPlans";

export const steps = [
  {
    component: ServiceCheck,
    name: "Serviceability Check",
    title: (
      <Title className="main-title-lg">
        View Servicable Plans
      </Title>
    ),
    subtitle: (
      <SubTitle className="px-0 mb-4 fst-italic subtitle-lg">
        To get started, please fill out the address.
      </SubTitle>
    ),
    mobileTitle: (
      <Title className="main-title-sm">
        View Servicable Plans
      </Title>
    ),
    mobileSubtitle: (
      <SubTitle className="p-0 fst-italic subtitle-sm">
        To get started, please fill out the address.
      </SubTitle>
    ),
  },
  {
    component: ViewPlans,
    name: "View Plans",    
    title: (
      <Title className="main-title-lg">
        Here are the available plans for this address:
      </Title>
    ),
    mobileTitle: (
      <Title className="main-title-sm">
        Here are the available plans for this address:
      </Title>
    ),
  },
];

function sendContentHeight() {
  var height = document.body.scrollHeight;
  window.parent.postMessage({ height: height }, "*");
}

export default function CustomerSupport() {
  const step = useRecoilValue(stepAtom);
  const customer = useRecoilValue(customerAtom);
    
  const { isLoading } = useUpdateCustomerData();  

  window.addEventListener("resize", sendContentHeight);

  // TODO - remove this once older versions of SCO go away
  if (!isMobile) {
    document.body.style.backgroundColor = "#3e4d3c";
  }

  useEffect(() => {
    sendContentHeight();
  }, [step]);


  const CurrentComponent = steps[step].component;

  const isFirstStep = step === 0;
  
  return (    
    <div className="App">
      {isMobile ? (
        <div className="mobile-main-container">                
          <div className="title-container">
            {steps[step].mobileTitle}
            
            {customer.formatted_address ? 
              <SubTitle className="p-0 pb-4 fst-italic subtitle-sm">
                {customer.formatted_address}
              </SubTitle>
            :
              steps[step].mobileSubtitle
            }
          </div>
          <div className="current-component-container">
            <CurrentComponent isLoading={isLoading} />
          </div>
        </div>
      ) : (
        <div className={`main-container row w-75`}>
          <div className="title-container">
            {steps[step].title}
            
            {customer.formatted_address ? 
              <SubTitle className="px-0 mb-4 fst-italic subtitle-lg">
                {customer.formatted_address}
              </SubTitle>
            :
              steps[step].subtitle
            }
            
          </div>
          
          <div className={`current-component-container ${!isFirstStep && "col-12"} p-0`}>
            <CurrentComponent isLoading={isLoading} />
          </div>
        </div>
      )}
    </div>
    
  );
}
