import { useFeatureFlag } from "configcat-react";
import { useRecoilState } from "recoil";

import { customerAtom } from "../../atoms/customer";
import { stepAtom } from "../../atoms/step";

import Button from "../../components/Button";
import Input from "../../components/Input";
import { useState, useMemo, useRef, useEffect } from "react";
import ContactSupport from "../../components/ContactSupport";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import useCheckServiceability from "../../hook/useCheckServiceability";
import { extractAddressShortName, extractAddressLongName } from "../../helpers/placeHelper";
import { isMobile } from "react-device-detect";

const PHONE_USSA_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
const EMAIL_REGEX = /^[^\s@]+@\w+\.[^\s@]+$/;
const EMAIL_ERROR_MESSAGE = "Please enter a valid Email";

const MESSAGE = "Service is not available in your area";
const INVALID_ADDRESS_MESSAGE = "Please enter a valid address.";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || "AIzaSyCBUicJb-ooCWzodi9ztVYFMwjDT_FKyj8";
Geocode.setApiKey(GOOGLE_API_KEY);

const getAddressInfo = address => ({
  address: extractAddressShortName(address, "street_number") + " " + extractAddressShortName(address, "route"),
  city: extractAddressLongName(address, "locality"),
  state: extractAddressShortName(address, "administrative_area_level_1"),
  county: extractAddressShortName(address, "administrative_area_level_2"),
  country: extractAddressShortName(address, "country"),
  postal_code: extractAddressLongName(address, "postal_code"),
});

const UserInfo = ({ showMessage }) => {
  const { checkServiceability } = useCheckServiceability();
  const [customer, setCustomer] = useRecoilState(customerAtom);
  const [step, setStep] = useRecoilState(stepAtom);
  const { value: isCouponCodeEnabled } = useFeatureFlag("isCouponCodeEnabled", false);

  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [place, setPlace] = useState();
  const [message, setMessage] = useState(showMessage);
  const [extraData, setExtraData] = useState();
  const [enableBtn, setEnableBtn] = useState(false);
  const [discountCode, setDiscountCode] = useState(customer?.discount_code);
  const [localPostCode, setLocalPostCode] = useState("");
  const [isDoneUpdateExtradata, setIsDoneUpdateExtradata] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setDiscountCode(customer?.lms?.discountCode || "");
  }, [customer]);

  useEffect(() => {
    if (inputRef.current && customer.formatted_address) {
      inputRef.current.value = customer.formatted_address;
    }
  }, [customer.formatted_address]);

  const onChangeFirstName = e => {
    setFirstName(e.target.value);
  };

  const onChangeLastName = e => {
    setLastName(e.target.value);
  };

  const onChangeEmailName = e => {
    setEmail(e.target.value);
    setEmailError(e.target.value.length === 0 || e.target.value.match(EMAIL_REGEX) === null ? EMAIL_ERROR_MESSAGE : "");
  };

  const onChangePhone = e => {
    setPhone(e.target.value);
    setPhoneError(
      e.target.value.length !== 0 && e.target.value.match(PHONE_USSA_REGEX) === null ? "Please enter a valid USA phone number" : ""
    );
  };

  const formInvalid = useMemo(
    () => firstName === "" || lastName === "" || phone === "" || email === "" || emailError !== "" || phoneError !== "",
    [firstName, lastName, phone, email, emailError, phoneError]
  );

  const onHandleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    const cleanPhoneNumber = phone.replace(/\D/g, "").slice(-10);

    if (message || !place) return;
    if (!formInvalid) {
      setLoading(true);
      setCustomer({
        ...customer,
        ...place,
        ...extraData,
        step: step + 1,
        discount_code: discountCode,
        isServiceable: true,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        cleanPhoneNumber: cleanPhoneNumber,
        email: email,
      });
   
      // GTM event
      if(process.env.NODE_ENV === "production") {
        window.dataLayer.push({
          event: 'service_contact_info'
        });
      }

      setStep(1);
      setLoading(false);
    }
  };

  const onDiscountBlur = async () => {
    setEnableBtn(false);

    try {
      const res = await checkServiceability(localPostCode, discountCode);

      setExtraData({
        ...extraData,
        prices: res?.data?.prices,
      });
      setLocalPostCode(localPostCode);
      setMessage(res?.data?.isServiceable ? "" : MESSAGE);
      setEnableBtn(!!res?.data?.isServiceable);
    } catch (e) {
      setEnableBtn(false);
      setMessage(MESSAGE);
    }
    setIsDoneUpdateExtradata(false);
  };

  const onGetAddress = async val => {
    const postalCode = extractAddressLongName(val.address_components, "postal_code");

    // Validate the address components array to ensure it has the required fields
    if (!isValidAddressArray(val.address_components)) {
      setMessage(INVALID_ADDRESS_MESSAGE);
      setEnableBtn(false);
      setCustomer({ ...customer, isServiceable: false, place: val, step: 0 });
      return;
    }

    setPlace(val);
    if (!postalCode) {
      setMessage(MESSAGE);
      setEnableBtn(false);
      return;
    }
    setLoading(true);
    const addressInfo = getAddressInfo(val.address_components);
    try {
      const res = await checkServiceability(postalCode, discountCode);
      setLocalPostCode(postalCode);
      const newExtraData = {
        officeId: res?.data?.officeId,
        prices: res?.data?.prices,
        ...addressInfo,
      };
      setExtraData(newExtraData);
      const isServiceable = res?.data?.isServiceable;
      setMessage(isServiceable ? "" : MESSAGE);
      setEnableBtn(!!isServiceable);
      if (!isServiceable) {
        setCustomer({ ...customer, isServiceable: false, place: place, extraData: newExtraData, step: 0 });
      }
    } catch (e) {
      setEnableBtn(false);
      setCustomer({ ...customer, isServiceable: false, place: val, step: 0 });
      setMessage(MESSAGE);
    }
    setLoading(false);
  };

  const handleAddressChange = e => {
    Geocode.fromAddress(e.target.value).then(
      response => {
        onGetAddress(response.results[0]);
      },
      error => {
        setMessage(INVALID_ADDRESS_MESSAGE);
        setEnableBtn(false);
      }
    );
  };

  const isValidAddressArray = addressComponents => {
    const requiredTypes = ["street_number", "locality"];
    const typesPresent = addressComponents.map(component => component.types).flat();
    return requiredTypes.every(type => typesPresent.includes(type));
  };

  return (
    <>
      <div className={`${isMobile ? "" : "w-75"} mx-auto`}>
        <form className="container py-2" onSubmit={onHandleSubmit}>
          <div className="contact p-lg-4 p-sm-3">
            <div className="contact-title fs-lg fs-sm">About You / Contact Info</div>
            <div className="contact-title-note my-2">
              Fields with (<span className="text-danger">*</span>) are required
            </div>
            <div className="row">
              <div className="col-sm-6 col-12 mb-2">
                <Input
                  data-testid="contact-info-firstname"
                  required
                  label="First"
                  value={firstName}
                  onChange={onChangeFirstName}
                  type="text"
                />
              </div>
              <div className="col-sm-6 col-12 mb-2">
                <Input data-testid="contact-info-lastname" required label="Last" value={lastName} onChange={onChangeLastName} type="text" />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-12 mb-2">
                <Input data-testid="contact-info-phone" required label="Phone" value={phone} onChange={onChangePhone} type="text" />
                {phoneError.length !== 0 && <div className="text-danger">{phoneError}</div>}
              </div>
              <div className="col-sm-6 col-12 mb-2">
                <Input data-testid="contact-info-email" required label="Email" value={email} onChange={onChangeEmailName} type="text" />
                {emailError.length !== 0 && <div className="text-danger">{emailError}</div>}
              </div>
            </div>
            <div className="container-step-1 p-0">
              <div className="form-container p-0 border-0 shadow-none mt-0">
                <div className="mb-2">
                  <div className="label-sm">
                    Address<span className="text-danger">*</span>
                  </div>
                  <Autocomplete
                    data-testid="autocomplete"
                    options={{
                      componentRestrictions: { country: "us" },
                      types: ["geocode", "establishment"],
                    }}
                    apiKey={GOOGLE_API_KEY}
                    onPlaceSelected={() => inputRef.current.blur()}
                    onBlur={handleAddressChange}
                    ref={inputRef}
                    language="en"
                    className="text-center form-control mb-1"
                  />
                </div>
                {isCouponCodeEnabled && (
                  <div className="discount">
                    <div className="label-sm">Discount Code</div>
                    <input
                      value={discountCode}
                      onChange={e => setDiscountCode(e.target.value)}
                      onBlur={onDiscountBlur}
                      placeholder="Enter a discount code"
                      className="form-control text-center mb-3"
                      type="text"
                      data-testid="discount-code"
                    />
                  </div>
                )}

                {message && <h4 className="text-danger">{message}</h4>}
                <div className="description mt-1 mb-1">
                  Why do we need your information? Your address allows us to determine if you are in an area we service.
                </div>
              </div>
            </div>
            <div className="contact-terms my-2">
              By clicking {"Submit"}, I authorize Aptive to call me and/or text me with offers about its services at the phone number above,
              using an autodialer or prerecorded messages. Consent not required for purchase. Message and data rates may apply. Promotion is
              subject to local service pricing, and may not apply to some services. For information about how we collect and use personal
              information, please read our{" "}
              <a href="https://goaptive.com/privacy-policy" rel="noreferrer" target="_blank">
                Privacy Policy
              </a>
              .
            </div>
          </div>

          <div className="contact-btns m-3">
            <div className="contact-btns-right">
              <Button
                testId="contact-info-submit"
                isLoading={loading || isDoneUpdateExtradata}
                disabled={!enableBtn || formInvalid || isDoneUpdateExtradata}
                label="Select a Plan"
                type="submit"
              />
            </div>
          </div>
        </form>
        <ContactSupport />
      </div>
    </>
  );
};

export default UserInfo;
