var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import classNames from 'classnames';
import { ThemeContext } from '../../context/ThemeContext';
import { AvatarImgs } from '../../shared/avatar';
export var Avatar = function (_a) {
    var id = _a.id, label = _a.label, imgSrc = _a.imgSrc, className = _a.className, _b = _a.size, size = _b === void 0 ? "default" : _b, props = __rest(_a, ["id", "label", "imgSrc", "className", "size"]);
    var avatar = useContext(ThemeContext).theme.avatar;
    var baseStyle = avatar.base;
    var sizeStyles = {
        "small": avatar.size.small,
        "default": avatar.size.default,
        "medium": avatar.size.medium,
        "large": avatar.size.large,
        "xl": avatar.size.xl,
        "2xl": avatar.size["2xl"],
        "3xl": avatar.size["3xl"],
    };
    var cls = classNames(baseStyle, sizeStyles[size], className);
    return (_jsx("span", __assign({ id: id, className: cls, role: "img", style: { backgroundImage: "url(".concat(imgSrc ? imgSrc : AvatarImgs.placeholder.imgSrc, ")") }, "aria-label": label ? label : AvatarImgs.placeholder.label }, props)));
};
