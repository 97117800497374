import React from "react";
import { useRecoilValue } from "recoil";
import { customerAtom } from "./../../atoms/customer";
import { formatDate } from "../../helpers/formatDate";
import { PRO_SERVICE_ID, PREMIUM_SERVICE_ID } from "../../constant";

const formatPhoneNumber = phoneNumberString => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumberString;
};

const CustomerSummaryInfo = ({ testid, className }) => {
  const customer = useRecoilValue(customerAtom);
  const planName = () => {
    switch (customer.planNumber.toString()) {
      case PRO_SERVICE_ID.toString():
        return "Pro";      
      case PREMIUM_SERVICE_ID.toString():
        return "Premium";
      default:
        return "";
    }
  };

  return (
    <div data-testid={testid} className={`customerSummaryInfo ${className}`}>
      {customer?.formatted_address && (
        <div className="address">
          <div className="title">Service Address</div>
          <div className="content" data-testid="customer-formatted-address">
            {customer.formatted_address}
          </div>
        </div>
      )}
      {customer?.planNumber && (
        <div className="address">
          <div className="title">Plan</div>
          <div className="content" data-testid="customer-plan-name">{`Aptive ${planName()}`}</div>
        </div>
      )}
      {customer?.firstName && (
        <div data-testid="about-you" className="address">
          <div className="title">About you</div>
          <div className="content">
            <div className="about-row d-flex gap-5">
              <span className="mr-3" data-testid="customer-name">
                {customer.firstName} {customer.lastName}
              </span>
              <span className="mr-3">{customer.email}</span>
              <span className="mr-3">{formatPhoneNumber(customer.phone)}</span>
            </div>
          </div>
        </div>
      )}
      {customer?.worldpay && (
        <div data-testid="payment" className="address">
          <div className="title">Payment</div>
          <div className="content" data-testid="customer-worldpay">
            {customer.firstName} {customer.lastName} **** **** **** {customer.worldpay.LastFour}
          </div>
        </div>
      )}

      {customer?.payment_data && (
        <div data-testid="payment" className="address">
          <div className="title">Payment</div>
          <div className="content" data-testid="customer-payment-data">
            {customer.firstName} {customer.lastName} **** **** **** {customer.payment_data.lastFour}
          </div>
        </div>
      )}

      {customer.appointment && (
        <div data-testid="initial-appointment" className="address">
          <div className="title">Initial Appointment</div>
          {customer.selectedSpot.date && customer.selectedSpot.window && (
            <div className="content" data-testid="customer-appointment">
              {formatDate(customer.selectedSpot.date)} {customer.selectedSpot.window}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomerSummaryInfo;
