import { useEffect, useMemo, useState } from "react";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import find from "lodash/find";
import flattenDeep from "lodash/flattenDeep";
import isThisWeek from "date-fns/isThisWeek";
import addWeeks from "date-fns/addWeeks";
import isSameWeek from "date-fns/isSameWeek";
import { apiRequest } from "../api/apiRequest";
import { getAvailableSpotsUrl } from "../api/apiEndpoints";

const isInNextWeek = date => isSameWeek(addWeeks(new Date(), 1), date);

export const groupByWeek = spots => {
  if (!spots) return [];

  return Object.values(
    groupBy(
      orderBy(spots, sport => sport?.date),
      spot => {
        if (isThisWeek(new Date(spot?.date))) {
          return 0;
        } else if (isInNextWeek(new Date(spot?.date))) {
          return 1;
        } else {
          return 2;
        }
      }
    )
  ).slice(0, 2);
};

// const getHour = (time) => +time?.split(":")[0];

export const findSampleSpot = (spots, window) => {
  if (!spots) return null;
  return find(spots, spot => spot?.window === window) || null;
};

export const getSampleSpots = spots => {
  if (!spots) return {};

  let sampleSpot = {};
  const amSpot = findSampleSpot(spots, "AM");
  const pmSpot = findSampleSpot(spots, "PM");

  if(amSpot) sampleSpot['AM'] = amSpot;
  if(pmSpot) sampleSpot['PM'] = pmSpot;

  return sampleSpot;
};

export const groupByDay = spots => {
  if (!spots) return {};
  const result = groupBy(spots, spot => spot?.date);
  Object.keys(result).forEach(date => (result[date] = getSampleSpots(result[date])));
  return result;
};

export const formatAroSpots = spots => groupByWeek(spots).map(weekSpots => groupByDay(weekSpots));

const useGetAvailableSpots = (customerId, accessToken, location, onSuccess, initSpots = [], initGetData = false) => {
  const [spots, setSpots] = useState(initSpots);
  const [loading, setLoading] = useState(initGetData);
  const { lat, lng } = location || {};

  const getData = async ({ signupRes, callback } = {}) => {
    setLoading(true);
    try {
      const res = await apiRequest({
        url: getAvailableSpotsUrl({ customerId: customerId || signupRes?.customerId, lng, lat }),
        configs: {
          headers: {
            Authorization: `Bearer ${accessToken || signupRes?.accessToken}`,
          },
        },
      });
      setSpots(res?.data?.spots);
      onSuccess?.(res?.data?.spots);
      callback?.(res?.data?.spots);
    } finally {
      setLoading(false);
    }
  };

  const availableSpots = useMemo(() => (spots && formatAroSpots(spots)) || [], [spots]);

  const flattenSpots = useMemo(
    () => flattenDeep((availableSpots || []).map(week => Object.values(week).map(day => Object.values(day)))),
    [availableSpots]
  );

  const extractSpotIds = (date, window) => {
    return spots.filter(spot => spot?.date === date && spot?.window === window);
  };

  useEffect(() => {
    if (initGetData) {
      getData();
    }
  }, [initGetData]);

  return { availableSpots, loading, extractSpotIds, flattenSpots, getData };
};

export default useGetAvailableSpots;
