import DaySchedule from "./DaySchedule";
import some from "lodash/some";
import { isMobile } from "react-device-detect";

export default function WeekSchedule({ week, isThisWeek, onSelectSpot, selectedSpot, unavailableSpots, testid }) {
  const checkAvailability = (date, window) => {
    return some(unavailableSpots, spot => spot?.date === date && spot?.window === window) ? null : week[date][window];
  };

  return (
    <div data-testid={testid} className={`${!isMobile && "d-flex justify-content-between"}`}>
      {Object.keys(week).map(date => (
        <DaySchedule
          key={date}
          date={date}
          amSpot={checkAvailability(date, "AM")}
          pmSpot={checkAvailability(date, "PM")}
          selectedSpot={selectedSpot}
          onSelectSpot={onSelectSpot}
        />
      ))}
    </div>
  );
}
