export const extractAddressLongName = (addressComponents, addressPart) => {
    return (
        addressComponents.find((item) => item.types[0] === addressPart)
            ?.long_name || null
    );
};
export const extractAddressShortName = (addressComponents, addressPart) => {
    return (
        addressComponents.find((item) => item.types[0] === addressPart)
            ?.short_name || null
    );
};