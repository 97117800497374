import { createRoot } from "react-dom/client";
import FullStory from "react-fullstory";
import { ConfigCatProvider } from "configcat-react";
import { RecoilRoot } from "recoil";

import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const FULLSTORY_ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID || "";

// ConfigCat
const CONFIGCAT_SDK_KEY = process.env.REACT_APP_CONFIGCAT_SDK_KEY || '';

root.render(
  <>
    {process.env.NODE_ENV === "production" ? (
      <FullStory org={FULLSTORY_ORG_ID} />
    ) : null}
    <ConfigCatProvider sdkKey={CONFIGCAT_SDK_KEY}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </ConfigCatProvider>
  </>
);
