var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
var Modal = (function (_a) {
    var className = _a.className, children = _a.children, open = _a.open, onClickOutside = _a.onClickOutside;
    var modalRef = useRef(null);
    var modalContentRef = useRef(null);
    var handleClickOutside = function (event) {
        var _a, _b;
        if (onClickOutside) {
            var target = event.target;
            if (((_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.contains(target)) && !((_b = modalContentRef.current) === null || _b === void 0 ? void 0 : _b.contains(target))) {
                onClickOutside();
            }
        }
    };
    useEffect(function () {
        document.addEventListener("click", handleClickOutside);
        return function () {
            document.removeEventListener("click", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!open) {
        return null;
    }
    return (_jsx("div", __assign({ "data-testid": "dialog", ref: modalRef, className: "fixed z-50 top-0 left-0 w-full h-full overflow-auto flex", style: { backgroundColor: "rgba(0, 0, 0, 0.1)" } }, { children: _jsx("div", __assign({ ref: modalContentRef, className: "shadow-high rounded bg-white m-auto ".concat(className) }, { children: children })) })));
});
export default Modal;
