import { getCheckServiceabilityUrl } from "../api/apiEndpoints";
import { apiRequest } from "../api/apiRequest";

const useCheckServiceability = () => {
  const checkServiceability = async (postalCode, discountCode) => {
    return apiRequest({
      url: getCheckServiceabilityUrl({ postalCode, discountCode }),
    });
  };

  return { checkServiceability };
};

export default useCheckServiceability;
