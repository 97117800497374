import { useRecoilState } from "recoil";
import { useFeatureFlag } from "configcat-react";

import { customerAtom } from "../../atoms/customer";
import { stepAtom } from "../../atoms/step";

import ContactSupport from "../../components/ContactSupport";
import { PREMIUM_SERVICE_ID, PRO_SERVICE_ID } from "./../../constant";
import Title from "../../components/Title";

export default function SelectPlan() {
  const [step, setStep] = useRecoilState(stepAtom);
  const [customer, setCustomer] = useRecoilState(customerAtom);  
  const { value: usePlanBuilderAddons, loading: usePlanBuilderAddonsLoading } = useFeatureFlag("usePlanBuilderAddons", false);

  const handleSelectPlan = planNumber => {
    // GTM event
    if(process.env.NODE_ENV === "production") {
      window.dataLayer.push({
        event: 'choose_a_plan'
      });
    }

    setCustomer({ ...customer, planNumber });
    setStep(step + 1);
  };

  // Will not include row is all plans do not include the addon
  const includePlanRow = (row) => {        
    let include = false;
    Object.keys(row.isAvailable).forEach((planKey) => {
      if(row.isAvailable[planKey]) {
        include = true;
      }
    })                

    return include;
  }

  const createPlanNameColumn = (planId) => {
    let planNameRows = [];
    Object.keys(customer.addons).forEach((key) => {
      const include = includePlanRow(customer.addons[key]);
      if(include) {
        planNameRows.push(
          <div key={key} data-testid={key}>{key}</div>
        );
      }
    })

    return planNameRows;
  }

  const createPlanColumn = (planId) => {
    let planRows = [];
    Object.keys(customer.addons).forEach((key) => {
      let include = includePlanRow(customer.addons[key]);
      
      if(include) {
        if(customer.addons[key].isAvailable[planId]) {                      
          planRows.push(
            <div key={key}>
              <span className="et-pb-icon icon-green"></span>
            </div>
          );
        } else {
          planRows.push( 
            <div key={key}>
              <span className="et-pb-icon icon-close">-</span>
            </div>
          );
        }
      }
    });

    return planRows;
  }

  // TODO: Update this line when feature flag is available
  // customer.prices?.promoPrices = null;

  return (
    <div className="step step-2 plan">
      <Title>Great! We can service your home!</Title>

      <section className="plan mt-2">
        <h2 className="title desktop">Choose your Aptive plan</h2>
        <div className="row plan-top mb-3">
          <div className="col-lg-4"></div>
          <div className="col-lg-4 col-md-12 mb-sm-0 mb-5">
            <div className="pro">
              <div className="pro-row">
                <h2 className="pb-0" data-testid="pro-plus-title">
                  Pro
                </h2>
              </div>

              <p data-testid="pro-plus-description">
                Treatments every 30-60 days from April to October. From November to March, treatments every 50-80 days.
              </p>
              <h2 className="price ">
                <span data-testid="pro-plus-initial" className="price-value">
                  {customer.prices?.promoPrices?.proInitial ? (
                    <>
                      <div className="promo-price-wrapper">
                        <div className="price-wrapper">
                          <div className="promo-prices">
                            <div className="old-price">${customer.prices?.proInitial}</div>
                            <div className="new-price">${customer.prices?.promoPrices?.proInitial}</div>
                          </div>
                          <div className="online-promotion">ONLINE PROMOTION</div>
                        </div>
                        <div className="treatment-description">initial treatment</div>
                      </div>
                      +<br />
                    </>
                  ) : (
                    <>${customer.prices?.proInitial}</>
                  )}
                </span>
                {customer.prices?.promoPrices?.proInitial ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <span>initial treatment</span> <br />+<br />
                  </>
                )}

                {customer.prices?.promoPrices?.proPlan ? (
                  <div className="promo-price-wrapper">
                    <div className="price-wrapper">
                      <div className="promo-prices">
                        <div className="old-price">${customer.prices?.proPlan}</div>
                        <div className="new-price">${customer.prices?.promoPrices?.proPlan}</div>
                      </div>
                    </div>
                    <div className="treatment-description">standard treatment</div>
                  </div>
                ) : (
                  <span className="standard-wrapper">
                    <span className="price-value" data-testid="pro-plus-plan">
                      ${customer.prices?.proPlan}
                    </span>{" "}
                    <span>standard treatment</span>
                  </span>
                )}
              </h2>

              <button
                className="button button-plan reh-choose-plan"
                data-type="selectProPlus"
                id="button-plan-pro"
                onClick={() => handleSelectPlan(PRO_SERVICE_ID)}
                data-testid="pro-plus-get-started-btn-up-left"
              >
                Get Started
              </button>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 mb-3">
            <div className="pro-plus">
              <div className="pro-row">
                <h2 className="pb-0" data-testid="pro-premium-title">
                  Premium
                </h2>
                <div className="popular" data-testid="pro-premium-popular">
                  Most Popular
                </div>
              </div>

              <p data-testid="pro-premium-description">
                Treatments every 20-40 days from April to October. From November to March, treatments every 50-80 days.
              </p>
              <h2 className="price">
                <span data-testid="premium-initial" className="price-value">
                  {customer.prices?.promoPrices?.premiumInitial ? (
                    <>
                      <div className="promo-price-wrapper">
                        <div className="price-wrapper">
                          <div className="promo-prices">
                            <div className="old-price premium">${customer.prices?.premiumInitial}</div>
                            <div className="new-price">${customer.prices?.promoPrices?.premiumInitial}</div>
                          </div>
                          <div className="online-promotion premium">ONLINE PROMOTION</div>
                        </div>
                        <div className="treatment-description">initial treatment</div>
                      </div>
                      +<br />
                    </>
                  ) : (
                    <>${customer.prices?.premiumInitial}</>
                  )}
                </span>
                {customer.prices?.promoPrices?.premiumInitial ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <span>initial treatment</span> <br />+<br />
                  </>
                )}

                {customer.prices?.promoPrices?.premiumPlan ? (
                  <div className="promo-price-wrapper">
                    <div className="price-wrapper">
                      <div className="promo-prices">
                        <div className="old-price premium">${customer.prices?.premiumPlan}</div>
                        <div className="new-price">${customer.prices?.promoPrices?.premiumPlan}</div>
                      </div>
                    </div>
                    <div className="treatment-description">standard treatment</div>
                  </div>
                ) : (
                  <span className="standard-wrapper">
                    <span className="price-value" data-testid="pro-plus-plan">
                      ${customer.prices?.premiumPlan}
                    </span>{" "}
                    <span>standard treatment</span>
                  </span>
                )}
              </h2>

              <button
                className="button button-plan reh-choose-plan"
                data-type="selectProPlus"
                id="button-plan-pro"
                onClick={() => handleSelectPlan(PREMIUM_SERVICE_ID)}
                data-testid="pro-plus-get-started-btn-up-right"
              >
                Get Started
              </button>
            </div>
          </div>
        </div>

        {
          (usePlanBuilderAddons && !usePlanBuilderAddonsLoading) ?
          (
            <div className="plan-row plan-bottom">
              <div className="plan-col">
                <div className="names">
                  {createPlanNameColumn()}
                </div>
              </div>
              <div className="plan-col">
                <div className="title mobile">Pro</div>
                <div className="includes">
                  {createPlanColumn(PRO_SERVICE_ID)}
                </div>
              </div>
              <div className="plan-col">
                <div className="title mobile">Premium</div>
                <div className="includes">
                  {createPlanColumn(PREMIUM_SERVICE_ID)}
                </div>
              </div>
            </div>
          )
          :
          (
            <div className="plan-row plan-bottom">
              <div className="plan-col">
                <div className="names">
                  <div data-testid="all-general">All General Pests*</div>
                  <div data-testid="eaves">Eaves</div>
                  <div data-testid="foundation">Foundation</div>
                  <div data-testid="in-ex-terior">Interior &amp; Exterior</div>
                  <div data-testid="re-serivces">Re-services at no cost</div>
                  <div data-testid="indoor">Indoor fly traps</div>
                  <div data-testid="outdoor">Outdoor wasp traps</div>
                  <div data-testid="flea-tick">Fleas &amp; Ticks</div>
                  <div data-testid="pantry">Pantry Pests</div>
                  <div data-testid="rodents">Rodents (exterior)</div>
                  <div data-testid="snails">Snails / Slugs / Aphids</div>
                  <div data-testid="mosquito">Mosquito</div>
                  <div data-testid="german">German Cockroach</div>
                </div>
              </div>
              <div className="plan-col">
                <div className="title mobile">Pro</div>
                <div className="includes">
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-close">-</span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-close">-</span>
                  </div>
                  <div>
                    <button
                      className="button button-plan-plus reh-choose-plan"
                      data-type="selectProPlus"
                      id="button-plan-pro"
                      onClick={() => handleSelectPlan(PRO_SERVICE_ID)}
                      data-testid="pro-plus-get-started-btn-down-left"
                    >
                      Get Started
                    </button>
                  </div>
                </div>
              </div>

              <div className="plan-col">
                <div className="title mobile">Premium</div>
                <div className="includes">
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-green"></span>
                  </div>
                  <div>
                    <span className="et-pb-icon icon-close">-</span>
                  </div>
                  <div>
                    <button
                      className="button button-plan-plus reh-choose-plan"
                      data-type="selectProPlus"
                      id="button-plan-pro"
                      onClick={() => handleSelectPlan(PREMIUM_SERVICE_ID)}
                      data-testid="pro-plus-get-started-btn-down-right"
                    >
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        <ContactSupport />
        <div data-testid="disclaimer" className="disclaimer">
          <p>
            To be eligible for this offer, you must (1) live in a serviceable area, and (2) agree to a contract with a minimum term of one
            year. Only valid for new customers. Other terms and conditions apply.
          </p>
          {customer.prices?.promoPrices !== null && (
            <p>
              Limited time offer. Offer available to new customers only through online checkout. Offer is not available through other
              channels. Discount based on the standard Initial Treatment price of $399. Requires a minimum 12-month Service Agreement with a
              minimum of 4 Standard Treatments.
            </p>
          )}
        </div>
      </section>
    </div>
  );
}