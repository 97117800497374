var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from "classnames";
import React, { useContext, useState } from "react";
import AptiveIcon from "../Icon";
import RecentPage from "./RecentPage";
import { ThemeContext } from "../../context/ThemeContext";
var Menu = React.forwardRef(function (params, ref) {
    var className = params.className, _a = params.open, open = _a === void 0 ? false : _a, _b = params.data, data = _b === void 0 ? [] : _b, onClose = params.onClose, _c = params.recentPagesPath, recentPagesPath = _c === void 0 ? [] : _c, props = __rest(params, ["className", "open", "data", "onClose", "recentPagesPath"]);
    var menu = useContext(ThemeContext).theme.menu;
    var _d = useState(undefined), activeSubMenu = _d[0], setActiveSubMenu = _d[1];
    var _e = useState(""), activedMenuTitle = _e[0], setActivedMenuTitle = _e[1];
    var handleMainMenuClick = function (menuId) {
        setActiveSubMenu(menuId);
        var clickedMenuItem = data.find(function (item) { return item.id === menuId; });
        setActivedMenuTitle(clickedMenuItem === null || clickedMenuItem === void 0 ? void 0 : clickedMenuItem.title);
    };
    var handleBackClick = function () {
        var prevData = data.find(function (item) { return item.id === activeSubMenu; });
        setActiveSubMenu(prevData.parentId);
    };
    var renderMenuItems = function (parentId) {
        var filteredItems = data.filter(function (item) { return item.parentId === parentId || (!parentId && !item.parentId); });
        var childrenBeforeLastChildrenIds = filteredItems
            .filter(function (item) { return data.some(function (parentItem) { return parentItem.parentId === item.id; }); })
            .sort(function (a, b) { return a.id - b.id; })
            .map(function (item) { return item.id; });
        return filteredItems.map(function (item) { return ((childrenBeforeLastChildrenIds.includes(item.id))
            ? _jsxs("li", __assign({ className: itemListClass, onClick: function () { return handleMainMenuClick(item.id); } }, { children: [_jsxs("div", __assign({ className: itemTitleClass }, { children: [item.title, _jsx(AptiveIcon, { className: rightIconClass, icon: "chevronRight", isFilled: true })] })), _jsx("div", __assign({ className: itemContentClass }, { children: item.content }))] }), item.id)
            :
                _jsx("li", __assign({ className: menu.item.base }, { children: _jsxs("a", __assign({ className: itemListLinkClass, href: item.path || '#' }, { children: [_jsx("div", __assign({ className: itemTitleClass }, { children: item.title })), _jsx("div", __assign({ className: itemContentClass }, { children: item.content }))] })) }), item.id)); });
    };
    var menuClass = classNames(className, menu.content, open ? "block" : "hidden");
    var itemListClass = classNames(menu.item.base, menu.item.list);
    var itemListLinkClass = menu.item.link;
    var itemTitleClass = menu.item.title;
    var itemContentClass = menu.item.contentText;
    var backItemClass = classNames(menu.item.base, menu.item.back);
    var backItemTitleClass = menu.item.backTitle;
    var activedItemTitle = menu.item.activedItemTitle;
    var rightIconClass = menu.item.rightIcon;
    var backIconClass = menu.item.backIcon;
    return (_jsx("ul", __assign({ role: "menu", ref: ref, className: menuClass }, props, { onClick: function (e) { return e.stopPropagation(); } }, { children: activeSubMenu ? (_jsxs(_Fragment, { children: [_jsxs("li", __assign({ className: backItemClass, onClick: handleBackClick }, { children: [_jsxs("div", __assign({ className: backItemTitleClass }, { children: [_jsx(AptiveIcon, { className: backIconClass, icon: "arrowLeft", isFilled: true }), "Back"] })), _jsx("div", __assign({ className: activedItemTitle }, { children: activedMenuTitle }))] })), renderMenuItems(activeSubMenu), (recentPagesPath.length > 0) && _jsx(RecentPage, { paths: recentPagesPath, title: "recent pages" })] })) : (renderMenuItems(undefined)) })));
});
export default Menu;
