import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";

import { stepAtom } from "../../../atoms/step";
import Title from "../../../components/Title";
import SubTitle from "../../../components/SubTitle";
import Button from "../../../components/Button";
import WeekSchedule from "./components/WeekSchedule";
import CustomerSummaryInfo from "../../../components/CustomerSummaryInfo";
import { formatDate } from "../../../helpers/formatDate";

export default function ScheduleAppointment({
  customer,
  autoBookSpot,
  isLoadingSpots,
  isSelectingDefaultSpot,
  availableSpots,
  unavailableSpots,
  handleSelectSpot,
  selectedSpot,
  handleConfirmAppointment,
  loading,
  errorMessage = "",
  appeaseMessageDuration = 10000,
}) {
  const [step, setStep] = useRecoilState(stepAtom);
  const [isLoadingMessage, setLoadingMessage] = useState(true);
  const [reschedule, setReschedule] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingMessage(false);
    }, appeaseMessageDuration);

    return () => clearTimeout(timer);
  }, []);


  const handleConfirm = () => {
    const sameSpot =
      (customer?.selectedSpot?.window === selectedSpot.window) &&
      (customer?.selectedSpot?.date === selectedSpot.date);
    if(autoBookSpot && sameSpot) {
      setStep(step + 1);
    } else {
      handleConfirmAppointment()
    }
  }

  return (
    <div className="step step-6">
      <Title>Appointment Confirmation</Title>
      <SubTitle dataTestId="sub-title">
        {autoBookSpot
          ? `To ensure a hassle-free experience, we've automatically scheduled your first appointment for the next available time slot. If this time doesn't suit you, please reschedule your appointment from the options below.`
          : "Which time works best for you? You will need to be home for your initial appointment. Below are the available times within the next 2 weeks, please select a time that works best for you."}
      </SubTitle>

      <div className="container">
        {isLoadingSpots || isSelectingDefaultSpot ? (
          isLoadingMessage ? (
            <div className="loading-msg" data-testid="loading-msg">
              <p>
                Please wait while we <span>{isLoadingSpots ? "search for available appointments" : "select the nearest for you"}</span>.
              </p>
              <i className="fa fa-circle-o-notch fa-spin fa-fw" />
            </div>
          ) : (
            <div className="loading-msg" data-testid="loading-msg">
              <p>
                We are still loading the available appointment times, <span>thanks for your patience.</span>
              </p>
              <i className="fa fa-circle-o-notch fa-spin fa-fw" />
            </div>
          )
        ) : (
          <>
            {customer?.selectedSpot && (
              <div className="title-section">
                {!reschedule && (
                  <>
                    <div className="subtitle">Your Appointment Has Been Scheduled for:</div>
                    <div className="title">
                      {formatDate(customer?.selectedSpot?.date)} {customer?.selectedSpot?.window}
                    </div>
                  </>
                )}

                {!autoBookSpot &&
                  <>
                    <div className="title">Schedule your Initial Appointment</div>
                    <div className="subtitle">Choose an alternative appointment window below</div>
                  </>
                }

                {reschedule &&
                  (autoBookSpot ?
                    <>
                      <div className="title">Reschedule your Initial Appointment</div>
                      <div className="mt-2 subtitle">Your Current Appointment:</div>
                      <div className="subtitle">
                        {formatDate(customer?.selectedSpot?.date)} {customer?.selectedSpot?.window}
                      </div>
                      <div className="mt-3 subtitle">Choose an alternative appointment window below</div>
                    </>
                    :
                      <>
                        <div className="title">Reschedule your Initial Appointment</div>
                        <div className="subtitle">Choose an alternative appointment window below</div>
                      </>
                  )
                }

              </div>
            )}
            <CustomerSummaryInfo testid="customer-summary" step={step} className="info" />
            {availableSpots?.length === 0 && (
              <SubTitle dataTestId="no-available-spots">
                We are having difficulty displaying available appointments. Please call 1-844-422-6619 to get an appointment scheduled.
              </SubTitle>
            )}

            {errorMessage && (
              <div className="my-3">
                <SubTitle dataTestId="error-message">
                  {errorMessage || "The appointment you selected is no longer available. Please choose another time"}
                </SubTitle>
              </div>
            )}

            {(reschedule || !autoBookSpot) &&
              availableSpots?.map((week, index) => (
                <WeekSchedule
                  testid="week-schedule"
                  key={index}
                  week={week}
                  isThisWeek={index === 0}
                  unavailableSpots={unavailableSpots}
                  onSelectSpot={handleSelectSpot}
                  selectedSpot={selectedSpot}
                />
              ))}
          </>
        )}

        <div className="contact-btns">

          {!isLoadingSpots && availableSpots?.length > 0 && 
            <div className="contact-btns-right">
              <Button
                testId="confirm-button"
                onClick={() => handleConfirm()}
                label={reschedule ? "Confirm New Appointment" : "View Order Summary"}
                disabled={!selectedSpot?.spotID}
                isLoading={loading}
              />
            </div>
          }

          {reschedule ?
              (autoBookSpot &&
                <div className="contact-btns-right">
                  <Button
                    testId="keep-current-button"
                    onClick={() => setStep(step + 1)}
                    label="Keep Current Appointment"
                    className="button-outline"
                    disabled={loading}
                  />
                </div>
              )
            :
              (customer?.selectedSpot) && (
                <div className="contact-btns-right">
                  <Button
                    testId="reschedule-button"
                    onClick={() => setReschedule(true)}
                    label="Reschedule Appointment"
                    isLoading={isLoadingSpots || isSelectingDefaultSpot}
                    className="button-outline"
                  />
                </div>
              )
          }
        </div>
      </div>
    </div>
  );
}