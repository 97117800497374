import React from "react";

const StepsMenu = ({
  steps = [],
  active,  
  handleStepChange
}) => {
  const canGoBack = steps[active].canGoBack;

  const renderIcon = index => {
    if ((index === active)) {
      return (
        <span className="fa-stack">
          {active < (steps.length-2) && <div className="step-vertical-line" />}
          <i className="fa fa-circle fa-stack-1x" style={{ color: "#FFF" }} />
          <i className="fa fa-dot-circle-o fa-stack-1x" />
        </span>
      );
    }

    if (index > active) {
      if(index === steps.length - 2) {
        return (
          <span className="fa-stack">
            <i className="fa fa-circle fa-stack-1x" style={{ color: "#FFF" }}/>
            <i className="fa fa-circle-o fa-stack-1x"/>
          </span>
        );
      } else {
        return (
          <span className="fa-stack">
            <div className="step-vertical-line"/>
            <i className="fa fa-circle fa-stack-1x" style={{ color: "#FFF" }}/>
            <i className="fa fa-circle-o fa-stack-1x"/>
          </span>
        );
      }
    }

    if (index < active) {
      return (
        <span className="fa-stack">
          <div className="step-vertical-line-green"/>
          <i className="fa fa-solid fa-check-circle fa-stack-1x" />
        </span>
      );
    }
  };

  const renderLabel = (step, index) => {
    if (index === active) {
      return (
        <div>
          <div className={`step-number`}>Step {index + 1}</div>
          <div className='fs-sm step-active'>{step.name}</div>
        </div>
      );
    }

    if (index < active) {
      return (
        <div>
          <div className={`step-number`}>Step {index + 1}</div>
          <div className='fs-sm step-complete'>{step.name}</div>
        </div>
      );
    }

    return (
      <div>
        <div className={`step-number`}>Step {index + 1}</div>
        <div className='fs-sm'>{step.name}</div>
      </div>
    );

  };

  const statusClass = index => {
    if (index === active) {
      return "active";
    }

    if (index < active) {
      return "done";
    }

    return "";
  };

  const visibleSteps = steps.filter(step => !step.hidden);

  const onHandleClickStep = (step, status, index) => {
    if (status === "done") { 
      if(index !== step) {
        steps.forEach((item, index) => {
          if (item.name === step.name) {
            handleStepChange(index);
          }
        });      
      }
    }
  };

  return (               
        <div className={`steps-menu`}>
          {visibleSteps.map((step, index) => {
            return (
              <div
                key={step.name}
                onClick={() => !(index <= 1 && active >= 3) && onHandleClickStep(step, statusClass(index), index)}
                className={`step-item ${statusClass(index)} ${canGoBack === false ? "disabled" : ""}`}
              >
                <div className="step-status">{renderIcon(index)}</div>
                <div className="step-data">{renderLabel(step, index)}</div>
              </div>
            );
          })}          
        </div>
    
  );
};

export default StepsMenu;
