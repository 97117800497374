import React from "react";

const Steps = ({ steps = [], active, className, handleStepChange }) => {
  const canGoBack = steps[active].canGoBack;

  const renderStatus = index => {
    if (index === active) {
      return (
        <span className="fa-stack">
          <i className="fa fa-circle fa-stack-1x" style={{ color: "#e1ff32" }} />
          <i className="fa fa-dot-circle-o fa-stack-1x" />
        </span>
      );
    }

    if (index > active) {
      return (
        <span className="fa-stack">
          <i className="fa fa-circle-o fa-stack-1x" />
        </span>
      );
    }

    if (index < active) {
      return (
        <span className="fa-stack">
          <i className="fa fa-check-circle-o fa-stack-1x" />
        </span>
      );
    }
  };

  const statusClass = index => {
    if (index === active) {
      return "active";
    }

    if (index < active) {
      return "done";
    }

    return "";
  };

  const visibleSteps = steps.filter(step => !step.hidden);

  const onHandleClickStep = (step, status, index) => {
    if (status === "done") { 
      if(index !== step) {
        steps.forEach((item, index) => {
          if (item.name === step.name) {
            handleStepChange(index);
          }
        });      
      }
    }
  };

  return (
    <div className={`new-steps-wrapper ${className || ""}`}>
      {visibleSteps.map((step, index) => {
        return (
          <div
            key={step.name}
            onClick={() => !(index <= 1 && active >= 3) && onHandleClickStep(step, statusClass(index), index)}
            className={`new-step-item ${statusClass(index)} ${canGoBack === false ? "disabled" : ""}`}
          >
            <div className="new-step-status">{renderStatus(index)}</div>
            <div className="new-step-data">
              <div className="new-step-number">Step {index + 1}</div>
              <div className="fs-sm">{step.name}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Steps;
