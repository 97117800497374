import { steps } from "../StandardCheckout";
import { TRACKING_URL } from "../api/apiEndpoints";
import { apiRequest } from "../api/apiRequest";
import { TRACKING_TOKEN } from "../constant";

export const mapStepNumberToStepName = stepNumber => steps[stepNumber].name.replace(" ", "_");

const useTracking = () => {
  const trackClick = async (fromStep, toStep) => {
    var data = [
      {
        date_created: new Date().toISOString(),
        product: "self_checkout",
        from: "frontend",
        name: `to/${mapStepNumberToStepName(toStep)}/from/${mapStepNumberToStepName(fromStep)}`,
      },
    ];

    return apiRequest({
      url: TRACKING_URL,
      method: "post",
      data,
      configs: {
        Authorization: `Token ${TRACKING_TOKEN}`,
      },
    });
  };

  return { trackClick };
};

export default useTracking;
