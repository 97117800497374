import React from "react";

const SubTitle = ({ dataTestId, children, className }) => {
  return (
    <div data-testid={dataTestId} className={`subtitle fs-sm ${className}`}>
      {children}
    </div>
  );
};

export default SubTitle;
