import { signupUrl } from "../api/apiEndpoints";
import { apiRequest } from "../api/apiRequest";

const useSignup = () => {
  const signup = async data => {
    return await apiRequest({
      url: signupUrl,
      method: "post",
      data,
      configs: {
        withCredentials: true,
      },
    });
  };

  return { signup };
};

export default useSignup;
