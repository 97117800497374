export const HOST_NAME = process.env.HOST_NAME || window.location.origin;

export const API_URL = process.env.REACT_APP_API_URL || "https://api.self-service.tst.goaptive.com";

export const LOGGING_URL = process.env.REACT_APP_LOGGING_URL || "https://sco.beta.goaptivetech.com";

export const TRACKING_URL = process.env.REACT_APP_TRACKING_URL || "https://beta.metrics.goaptivetech.com/a/logger/cxp";

export const RECORD_URL = process.env.REACT_APP_RECORD_URL || "https://api.lead-manager.stg.goaptive.com/api/session";

// API endpoints
export const authTokenUrl = `${API_URL}/api/v1/tokenex/authtoken`;
export const validateCreditCardUrl = `${API_URL}/api/v1/credit-card/validate`;
export const getCheckServiceabilityUrl = ({ postalCode, discountCode }) =>
  `${API_URL}/api/v1/customers/serviceability?postalCode=${postalCode}&discountCode=${discountCode}`;
export const getCreateAppointmentUrl = customerId => `${API_URL}/api/v1/customers/${customerId}/appointments`;
export const getUpdateAppointmentUrl = customerId => `${API_URL}/api/v1/customers/${customerId}/appointments/reschedule`;
export const getAvailableSpotsUrl = ({ customerId, lng, lat }) =>
  `${API_URL}/api/v1/spots?customerId=${customerId}&longitude=${lng}&latitude=${lat}`;
export const signupUrl = `${API_URL}/api/v1/signup`;
export const getUpdateCustomerDataUrl = () => `${LOGGING_URL}/api/v1/session/update`;
export const getUpdateCustomerNewDataUrl = () => `${API_URL}/api/v1/session/update`;
export const getFetchPaymentIframeUrl = officeId => {
  const url = `${API_URL}/api/v1/customers/payment-method-url`;
  const queryParams = new URLSearchParams({
    officeId,
    returnUrl: `${HOST_NAME}/callback.html`,
  });
  return `${url}?${queryParams}`;
};
export const createCustomerDataSessionUrl = () => `${LOGGING_URL}/api/v1/session/create`;
export const createCustomerDataNewSessionUrl = () => `${API_URL}/api/v1/session/create`;

export const getPlansUrl = ({ officeId }) =>
  `${API_URL}/api/v1/plan-builder?officeId=${officeId}&planPricingLevel=Standard&category=Service%20Pro`;

  
