import { useState } from "react";
import { useRecoilState } from "recoil";
import { isMobile } from "react-device-detect";
import { formatDate } from "../../helpers/formatDate";

import { customerAtom } from "./../../atoms/customer";
import { stepAtom } from "./../../atoms/step";
import { PRO_SERVICE_ID, PREMIUM_SERVICE_ID } from "./../../constant";

import Button from "./../../components/Button";
import useSignup from "./../../hook/useSignup";

import useGetAvailableSpots from "./../../hook/useGetAvailableSpots";
import { extractAddressShortName, extractAddressLongName } from "./../../helpers/placeHelper";

const formatPhoneNumber = phoneNumberString => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumberString;
};

export default function OrderSummary({ setSpots, isScheduleStep, isConfirmation }) {
  const [step, setStep] = useRecoilState(stepAtom);
  const [customer, setCustomer] = useRecoilState(customerAtom);
  const { signup } = useSignup();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("An error has occured");
  const { getData } = useGetAvailableSpots(customer.customerId, customer.accessToken, customer?.geometry?.location);

  const planName = () => {
    switch (customer.planNumber.toString()) {
      case PRO_SERVICE_ID.toString():
        return "Pro";
      case PREMIUM_SERVICE_ID.toString():
        return "Premium";
      default:
        return "";
    }
  };
  const getAddressInfo = address => ({
    address: extractAddressShortName(address, "street_number") + " " + extractAddressShortName(address, "route"),
    city: extractAddressLongName(address, "locality"),
    state: extractAddressShortName(address, "administrative_area_level_1"),
    county: extractAddressShortName(address, "administrative_area_level_2"),
    country: extractAddressShortName(address, "country"),
    postal_code: extractAddressLongName(address, "postal_code"),
  });

  const createAddress = () => {
    const address = getAddressInfo(customer.address_components);

    return (
      <>
        <div className="d-flex">
          <div>{address.address}</div>
        </div>
        <div>
          {address.city} {address.state}, {address.postal_code}
        </div>
      </>
    );
  };

  const onHandlePlaceOrder = async () => {
    setLoading(true);
    const cleanPhoneNumber = customer.phone.replace(/\D/g, "").slice(-10);
    const payload = {
      customer: {
        firstName: customer.firstName,
        lastName: customer.lastName,
        address: customer.address,
        city: customer.city,
        state: customer.state,
        county: customer.county,
        postalCode: customer.postal_code,
        phone: cleanPhoneNumber,
        email: customer.email,
        latitude: customer.geometry.location.lat,
        longitude: customer.geometry.location.lng,
      },
      subscription: {
        serviceId: customer.planNumber,
      },
      payment_data: {
        ...(customer.payment_data ? customer.payment_data : {}),
      },
      paymentProfile: {
        token: customer.worldpay?.PaymentAccountID,
      },
      use_promotional: customer.prices.promoPrices ? true : false,
      use_aptive_payment_service: customer.use_aptive_payment_service,
    };

    if (customer.discount_code) {
      payload.discount_code = customer.discount_code;
    }

    try {
      const res = await signup(payload);
      const newData = {
        ...customer,
        ...res?.data,
      };
      const callback = spots => {
        setCustomer({
          ...newData,
          spots,
          ...(!spots?.length && { getAvailableSpotsMessage: "Spots were not received" }),
        });
        setSpots(spots);
        setErrorMessage("");
        setStep(step + 1);
      };
      await getData({ signupRes: res?.data, callback });
    } catch (error) {
      setError(true);
      if (error.response.data.isDuplicate) {
        setErrorMessage("This customer account already exists.");
      } else {
        setErrorMessage("An error occured during the signup process.");
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`order-summary-component${isMobile ? "-mobile" : ""} py-3 rounded ${isConfirmation && "bg-white"}`}>
      <div className={`${!isMobile && "mx-auto"} ${isConfirmation ? "my-2" : "my-5"}`}>
        <div className="address">
          <div className="title">Service Address</div>
          <div className="content" data-testid="customer-formatted-address">
            {isMobile ? createAddress() : customer?.formatted_address}
          </div>
        </div>

        <div className="address">
          <div className="title">Plan</div>
          <div className="content" data-testid="customer-plan-name">{`Aptive ${planName()}`}</div>
        </div>

        <div data-testid="about-contact" className="address">
          <div className="title">About / Contact</div>
          <div className="content">
            <div className={`d-flex ${isMobile ? "flex-column" : ""}`}>
              <span data-testid="customer-name">
                {customer.firstName} {customer.lastName}
              </span>
              <span className="text-decoration-underline mx-3">{customer.email}</span>
              <span>{formatPhoneNumber(customer.phone)}</span>
            </div>
          </div>
        </div>

        {customer?.worldpay && (
          <div data-testid="payment" className="address">
            <div className="title">Payment</div>
            <div className="content" data-testid="customer-worldpay">
              {customer.firstName} {customer.lastName} **** **** **** {customer.worldpay.LastFour}
            </div>
          </div>
        )}

        {customer?.payment_data && (
          <div data-testid="payment" className="address">
            <div className="title">Payment</div>
            <div className="content" data-testid="customer-payment-data">
              {customer.firstName} {customer.lastName} **** **** **** {customer.payment_data.lastFour}
            </div>
          </div>
        )}
        {customer.appointment && (
          <div data-testid="initial-appointment" className="col address">
            <div className="title">Initial Appointment</div>
            {customer.selectedSpot.date && customer.selectedSpot.window && (
              <div className="content mb-0" data-testid="customer-appointment">
                {formatDate(customer.selectedSpot.date)} {customer.selectedSpot.window}
              </div>
            )}
          </div>
        )}
      </div>

      {!(isScheduleStep || isConfirmation) && (
        <div className="place-order-button-container">
          {error && (
            <div className="place-order-error-text">
              <div>{errorMessage}</div>
              <div>Please contact our support team at 1-855-642-8632 for more help.</div>
            </div>
          )}
          <Button
            className={`place-order-button ${!isMobile && "w-auto px-5 mx-auto mb-4"}`}
            testId="order_summary-place_order"
            isLoading={loading}
            onClick={onHandlePlaceOrder}
            label={`Place Your Order ${!isMobile ? "" : "& Schedule Appointment"}`}
          />
        </div>
      )}
    </div>
  );
}
