import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { datadogRum } from '@datadog/browser-rum';
import TagManager from 'react-gtm-module';
import { DATA_DOG_ENV, GOOGLE_TAG_MANAGER_ID } from "./constant";

import StandardCheckout from "./StandardCheckout";
import ExpressCheckout from "./ExpressCheckout";
import NewCheckout from "./NewCheckout";
import CustomerSupport from "./CustomerSupport";
import MaintenanceMode from "./components/MaintenanceMode";
import { useFeatureFlag } from "configcat-react";

import "./styles.scss";

export default function App() {
  const { value: maintenanceModeValue, loading: maintenanceModeLoading } = useFeatureFlag("maintenanceMode", false);
  const { value: expressCheckoutValue } = useFeatureFlag("expressCheckout", false);
  const { value: newSco } = useFeatureFlag("newSco", false);

  // Okta authentication
  /*
  const oktaAuth = new OktaAuth({
    issuer: ISSUER,
    clientId: CLIENT_ID,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: SCOPES,
    state: STATE,
  });

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(
      toRelativeUrl(originalUri, window.location.origin),
    );
  };*/

  if(process.env.NODE_ENV === "production") {
    const tagManagerArgs = {
      gtmId: GOOGLE_TAG_MANAGER_ID,
    };
    TagManager.initialize(tagManagerArgs);
  }  

  datadogRum.init({
    applicationId: '73c0367a-4823-4a95-9f47-106f1346c6ef',
    clientToken: 'pub6cbdfe0fd73dfd91fbafb80eed55773d',
    site: 'us5.datadoghq.com',
    service: 'self-checkout-react',
    env: DATA_DOG_ENV,
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });

  return maintenanceModeValue && !maintenanceModeLoading ? (
    <MaintenanceMode />
  ) : (
    <BrowserRouter>
      {/*<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/login/callback" element={<LoginCallback />} />
        </Routes>
      </Security>*/}

      <Routes>
        <Route path="/" element={<StandardCheckout />} />
        <Route path="/servicability" element={<CustomerSupport />} />
        {expressCheckoutValue && <Route path="/express" element={<ExpressCheckout />} />}
        {newSco && <Route path="/v2" element={<NewCheckout />} />}        
      </Routes>
    </BrowserRouter>
  );
}
