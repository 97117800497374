import { apiRequest } from "../api/apiRequest";
import {
  getPlansUrl
} from "../api/apiEndpoints";

const useGetPlans = () => {
  const getPlans = async (officeId) => {
    return apiRequest({
      url: getPlansUrl({ officeId }),
    });
  };

  return { getPlans };
};

export default useGetPlans;
