var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useContext } from 'react';
import classNames from 'classnames';
import { ThemeContext } from '../../context/ThemeContext';
var Loading = forwardRef(function (_a, ref) {
    var className = _a.className, style = _a.style, props = __rest(_a, ["className", "style"]);
    var loading = useContext(ThemeContext).theme.loading;
    var classes = classNames('loading', className);
    var loadingContentStyle = loading.loadingContent;
    var loadingInsideContentStyle = loading.loadingInsideStyle;
    var ballContentStyle = loading.ball.content;
    var ballSpanStyle = loading.ball.span;
    var ballStyle = loading.ball.base;
    return (_jsxs("div", __assign({}, props, { ref: ref, className: classes, "aria-label": 'loading' }, { children: [_jsx("div", __assign({ className: loadingContentStyle }, { children: _jsx("div", { className: loadingInsideContentStyle }) })), _jsx("div", __assign({ className: ballContentStyle }, { children: _jsx("span", __assign({ className: ballSpanStyle }, { children: _jsx("span", { className: ballStyle }) })) }))] })));
});
export default Loading;
