import React from "react";

const ContactSupport = ({
  className,
  message = "Need additional help? Reach out to our support team at 1-855-642-8632.",
  testid = "contact-support",
}) => {
  return (
    <div data-testid={testid} className={`contact-supporter ${className}`}>
      {message}
    </div>
  );
};

export default ContactSupport;
