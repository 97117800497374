var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import classNames from 'classnames';
import Checkbox from '../Checkbox/Checkbox';
import AptiveIcon from '../Icon';
var DropdownItem = React.forwardRef(function DropdownItem(props, ref) {
    var children = props.children, _a = props.checkable, checkable = _a === void 0 ? false : _a, selected = props.selected, onClick = props.onClick, other = __rest(props, ["children", "checkable", "selected", "onClick"]);
    var _b = useContext(ThemeContext).theme, button = _b.button, dropdown = _b.dropdown;
    var baseFontStyle = dropdown.item.base;
    var itemContentStyle = dropdown.item.content;
    var itemActiveStyle = dropdown.item.active;
    var iconStyle = button.icon.default.size;
    var iconBaseColor = dropdown.item.icon.base.filled;
    var iconActiveColor = dropdown.item.icon.active.filled;
    var iconSelectedColor = dropdown.item.icon.selected.filled;
    var iconRightStyles = classNames(iconStyle, children && dropdown.item.icon.space.right, selected ? iconSelectedColor : iconBaseColor, iconActiveColor);
    var checkboxContentStyle = dropdown.checkboxContent;
    var cls = classNames(classNames(baseFontStyle, itemActiveStyle), itemContentStyle);
    return (_jsx(_Fragment, { children: checkable
            ? _jsx("button", __assign({ className: checkboxContentStyle, ref: ref }, other, { children: _jsx(Checkbox, __assign({ onChange: onClick, className: cls, checked: selected }, { children: children })) }))
            : _jsxs("button", __assign({ onClick: onClick, className: cls, ref: ref }, other, { children: [children, selected && _jsx(AptiveIcon, { className: iconRightStyles, icon: "check", isFilled: true })] })) }));
});
export default DropdownItem;
